$searchtextcolor: #a5a7a6;
$white: #fff;

:root,
[data-theme="default"] {
  // main
  --color-primary-darker: hsl(220, 90%, 36%);
  --color-primary-dark: hsl(220, 90%, 46%);
  --color-primary: hsl(220, 90%, 56%);
  --color-primary-light: hsl(220, 90%, 66%);
  --color-primary-lighter: hsl(220, 90%, 76%);
  --color-primary-a20: hsla(220, 90%, 56%, 0.2);

  --color-accent-darker: hsl(355, 90%, 41%);
  --color-accent-dark: hsl(355, 90%, 51%);
  --color-accent: hsl(355, 90%, 61%);
  --color-accent-light: hsl(355, 90%, 71%);
  --color-accent-lighter: hsl(355, 90%, 81%);

  // color contrast
  --color-bg: hsl(0, 0%, 100%);
  --color-bg-a00: hsla(0, 0%, 100%, 0);
  --color-contrast-lower: hsl(0, 0%, 95%);
  --color-contrast-low: hsl(240, 1%, 83%);
  --color-contrast-medium: hsl(240, 1%, 48%);
  --color-contrast-high: hsl(240, 4%, 20%);
  --color-contrast-higher: hsl(240, 8%, 12%);
  --color-contrast-higher-a90: hsla(240, 8%, 12%, 0.9);

  // semantic
  --color-border: var(--color-contrast-low);

  // ...
}

#root .__mm,
#root .__zg {
  font-family: Myanmar3 !important;
}

// ::-webkit-scrollbar {
//   height: 3px;
//   width: 3px;
// }

body {
  -webkit-overflow-scrolling: touch;
  background-color: #fafafa;
  overflow: overlay;
  position: relative;
}

.MuiFormControl-root {
  border: 0;
  margin: 0;
  display: inline-flex;
  padding: 0;
  position: relative;
  min-width: 0;
  flex-direction: column;
  vertical-align: top;
}

.loadingGifDiv {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  svg {
    z-index: 9999;
    inset: 0px;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .loadingMessage {
    font-size: 17px;
    color: #fff;
  }
}

.inWeb.bgKbz .homeCenterInfo {
  height: 130px !important;
  background-color: #0b55a8 !important;
  z-index: -1;
  margin-top: 0 !important;
}

.inWeb.bgViber .homeCenterInfo {
  height: 130px !important;
  background-color: #794aff !important;
  z-index: -1;
  margin-top: 0 !important;
}
.inWeb.bgCityReward .homeCenterInfo {
  height: 130px !important;
  background-color: #6e51a2 !important;
  z-index: -1;
  margin-top: 0 !important;
}

.bgkbz.covidInfo {
  background-color: #0b55a8 !important;
}
.bgCityReward.covidInfo {
  background-color: #6e51a2 !important;
}

.bgviber.covidInfo {
  background-color: #794aff !important;
}
.bgCityReward #fl-home-btn-search.btnDisable.btnCityReward {
  background-color: #6e51a2 !important;
}
.bgHome {
  // background-repeat: no-repeat;
  // background-position: center;
  // background-size: cover;
  // background: url("../images/background/four/background-desktop_01.jpg") left
  //     top no-repeat,
  //   url("../images/background/four/background-desktop_02.jpg") right top
  //     no-repeat,
  //   url("../images/background/four/background-desktop_03.jpg") left bottom
  //     no-repeat,
  //   url("../images/background/four/background-desktop_04.jpg") right bottom
  //     no-repeat;
  // background-size: 50.01% 50.01%;
  //background-origin: border-box;
  padding: 0px !important;
  //height: 550px;
  //min-height: 100vh;
  min-width: 100%;

  // .homeContainer {
  //   height: 550px !important;
  // }
  .homeCenterInfo {
    height: 130px !important;
    background-color: rgb(0, 174, 255);
    z-index: -1;
    margin-top: 0 !important;
  }

  .homeCenterInfoBlur {
    height: 130px !important;
    background-color: rgba(0, 174, 255, 0.5);
    z-index: -1;
    margin-top: 20px !important;
  }

  .DateRangePicker_picker {
    top: 47px !important;
  }

  .DateInput_fang {
    top: 30px !important;
  }
}

.ageSelect {
  width: 53%;
  float: left;
  margin: 5px 0px;

  .ageType {
    font-family: "Roboto", Myanmar3;
    font-weight: 400;
    font-size: 14px;
  }

  .ageLimit {
    font-family: "Roboto", Myanmar3;
    font-weight: 400;
    font-size: 12px;
    color: #6d8494;
  }
}

.logo {
  height: 36px;
  padding-right: 8px;
}

.kbzLogo,
.CRLogo {
  padding-left: 7px;
}

.MuiContainer-root {
  padding: 0px !important;
}

.menu {
  float: left;
  font-family: "Roboto", Myanmar3;
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #fff !important;
  // padding-right: 27px !important;
  padding-left: 0px !important;
  float: left;

  a {
    font-family: "Poppins", Myanmar3;
    font-weight: 400;
    font-size: 16px !important;
    font-weight: normal !important;
    color: #fff !important;
    text-decoration: none !important;
    width: 100%;

    span,
    img {
      float: left;
      font-size: 18px !important;
    }
  }
  &.agent-login {
    padding: 0;
  }
}
.react-flags-container {
  display: flex;
}
.menu:hover {
  background-color: transparent !important;
}
.header-menu-wrapper {
  width: 100%;
  .headline-wrap {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  @media (min-width: 781px) {
    background-color: #fff;
    box-shadow: rgba(171, 171, 171, 0.05) 0px 0px 9px 0px;
  }
}
.mobile_homeMenu {
  padding-block: 14px;
  max-width: 1300px !important;
  margin: auto !important;
  p,
  a,
  li,
  span {
    font-family: "Roboto" !important;
    font-size: 16px;
  }
  li.web-flag {
    padding-right: 0 !important;
    padding-block: 0 !important;
  }
  .MuiListItem-gutters {
    padding: 0;
  }
  @media (max-width: 1200px) {
    padding-inline: 2%;
  }
  .product-wrap {
    display: flex;
    align-items: center;
    gap: 50px;
    @media (max-width: 1300px) {
      gap: 30px;
    }
  }
  .wrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;
    @media (max-width: 1200px) {
      width: 100% !important;
    }
    @media (max-width: 1300px) {
      gap: 12px;
    }
    @media (max-width: 960px) {
      gap: 15px;
    }
    .header-createaccount {
      padding: 0;
      padding-inline: 10px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      font-size: 16px !important;
      span {
        font-size: 16px !important;
      }
    }
  }
  .react-flags-container {
    img {
      width: 20px;
      height: 20px;
    }
  }
}
.menuList {
  padding: 0px 12px 0px !important;
  margin-top: 20%;

  li.web-flag {
    padding-right: 0px !important;
  }

  li.agent-login {
    position: relative;

    img {
      width: 20px;
      position: absolute;
    }
  }
}

.header_login_box {
  padding: 8px 18px;
  border: 1px solid #7c7c7c;
  border-radius: 6px;
  color: #7c7c7c;
  margin-left: 0 !important;
  font-size: 14px;
  cursor: pointer;
}

.header-createaccount {
  padding: 8px 18px;
  border: 1px solid #00aeef;
  background-color: #00aeef;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  span {
    margin-left: 0 !important;
    font-size: 14px;
  }
}

.desktop_login_icon {
  width: 25px;
  // padding-left: 30px;
}

.desktop_loggedin_icon {
  width: 25px;
  // width: 30px;
  // padding-left: 30px;
}

.menuList ul:focus {
  border-color: transparent;
  outline: none;
}

.bgHome,
#bgBalloon,
#bgBus,
#bgCar,
#bgHotel {
  .menuList li a,
  .menuList li a > span {
    font-size: 16px !important;
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 400;
  }

  .menuList li:last-child span {
    padding-bottom: 1px;
  }

  .menuList li:last-child span.myanmarFont {
    padding-bottom: unset;
  }
}

.bgBus .homeContainer {
  // height: 100vh;
  height: auto;
}

.homeCenterInfo {
  font-family: "Poppins", Myanmar3;
  font-weight: 400;
  font-size: 40px;
  color: #fff;
  font-weight: 500;
  text-align: center;
  margin-top: -50px !important;
  position: relative;
  height: 0;

  .divDiscover {
    margin: auto;
    max-width: 610px;
    text-align: right;
  }
}

.homeContainerSearch {
  max-width: 100% !important;
  margin: auto;
  height: auto;
}

.bgHome {
  .discover {
    font-size: 32px;
    display: none;
  }

  .myanmar {
    font-size: 70px;
    display: none;
  }
  // .search-wrapper {
  //   @media (max-width: 1200px) {
  //     padding-inline: 2%;
  //   }
  // }
}

.bgCar {
  .flag-dropdown {
    .arrow {
      left: 20px !important;
    }
  }
}

.myanmar {
  font-family: "Poppins", Myanmar3 !important;
  font-weight: 700;
  font-size: 100px;
  color: #fff;
  line-height: 60px;
  position: relative;
}

.search {
  background-color: #fff;
  height: 74px;
  border-radius: 5px;
  padding: 0px 0px 0px 30px;
}

.hotelSearchDiv {
  background-color: #fff;
  height: 74px;
  border-radius: 5px;
  // max-width: 1000px;
  padding: 0px 0px 0px 30px;
  margin-top: 20px !important;
  .hotel-textbox {
    flex-grow: 1;
    border-right: "1px solid #e4e4e4";
    display: flex;
    align-items: center;
    .MuiTextField-root {
      margin: 0 !important;
      min-height: 35px;
      .MuiInputLabel-shrink {
        margin-top: -2px;
      }
    }
    .MuiInputBase-formControl {
      margin-top: 16px;
    }
  }
}

@media (max-width: 780px) {
  .hotelSearchDiv {
    padding: 0;
  }
}

.busSearch {
  background-color: #fff;
  max-width: 1000px;
  margin: auto;
  height: 74px;
  border-radius: 5px;
  // margin-top: -139px !important;
  // margin-top: -68px !important;
  margin-top: 80px !important;
  padding: 0px 0px 0px 30px;
}

.nopadding {
  padding: 0px !important;
}

.pull-right {
  float: right !important;
}

.searchIcon {
  width: 26px;
  height: 26px;
  margin-top: 24px;
  padding-right: 10px;
  float: left;
}

.btnSearch {
  max-width: 120px !important;
  max-height: 74px !important;

  button {
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 400;
    font-size: 18px !important;
    text-transform: capitalize !important;
    height: 100%;
    border-radius: 0px !important;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    box-shadow: none !important;
    width: 121px !important;
    margin-right: -1px;
    min-height: 74.5px;

    span {
      font-size: 20px;
      font-weight: bold;
    }
  }

  // .btnActive {
  //   //background-color: rgb(196, 112, 22) !important;
  //   background-color: #fc9d00 !important;
  // }
}

#fl-home-btn-search span {
  pointer-events: none;
}

.MuiFormLabel-root.Mui-focused,
.selectedAiport {
  color: $searchtextcolor !important;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 400;
  font-weight: 500 !important;
  font-size: 16px !important;
}

.MuiFormLabel-root {
  color: $searchtextcolor !important;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 400 !important;
  font-size: 16px !important;
}

.container {
  width: 1200px;
  max-width: 1200px !important ;
  margin: auto !important;

  .MuiSvgIcon-root {
    color: #00b7f0 !important;
  }
}

.header-mainlogo {
  display: flex;
  justify-content: flex-start;
}
.CR-header-mainlogo {
  min-width: 300px;
}
.header-mainlogo.product-header {
  min-width: 200px !important;
}
.product-header img:nth-child(1) {
  max-width: 100px;
}

.header-slogan {
  display: flex;
  justify-content: flex-end;
}

.homeContainer.homeFlightSearch {
  max-width: 1100px;
}

.homeContainer {
  // max-width: 1300px;
  width: unset !important;
  display: block;
  margin: auto;
  .DateInput {
    width: 100% !important;
  }

  .DateInput_input {
    font-size: 16px !important;
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 700;
    color: #231f20 !important;
    width: 92% !important;
    padding: 2px 13px 7px 7px;
    float: left;
  }

  .flightSearch .DateInput_input {
    font-size: 18px !important;
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 700;
    color: #231f20 !important;
    width: 90% !important;
    padding: 7px 11px 9px;
    float: left;
  }

  .DateInput__disabled {
    width: 0px !important;
  }

  .DateInput_1 {
    #start_date_id {
      position: inherit;
      padding: 2px 13px 7px 7px;
    }

    #end_date_id {
      padding: 7px 22px 7px 0px;
      margin: auto;
      margin-top: 5px;
      width: 0px !important;
    }
  }

  .flightSearch .DateInput_1 {
    #start_date_id {
      padding: 10px 2px 7px 7px !important;
      min-width: 130px !important;
    }
  }

  .flightSearch .DateInput_1 {
    #return_start_date_id {
      padding: 10px 2px 7px 7px !important;
    }
  }

  .selected #end_date_id {
    margin-top: -10px !important;
  }

  .selected .DateRangePickerInput {
    padding-top: 0px !important;
  }

  .DateInput_input::placeholder {
    font-size: 16px !important;
    font-weight: normal !important;
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 400;
    color: $searchtextcolor !important;
    padding: 0px 11px 9px;
  }

  .DateRangePickerInput,
  .DateInput_input__focused {
    border: none !important;
    width: 100%;
  }

  .DateInput {
    line-height: 40px !important;
    // width: 50% !important;
    float: left;
  }
}

.homeContainer.pageHeaderContainer {
  max-width: 1100px;
}

.hotelContainer {
  // width: 1200px;
  //max-width: 1000px;
  //display:block;
  // min-width: 1200px;
  //padding: 0px 50px;
  //height: 100vh;
  // margin: auto;
  .DateInput {
    width: 100% !important;
  }

  .DateInput_input {
    font-size: 16px !important;
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 700;
    color: #231f20 !important;
    width: 95% !important;
    padding: 2px 11px 7px;
    float: left;
  }

  .flightSearch .DateInput_input {
    font-size: 18px !important;
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 700;
    color: #231f20 !important;
    width: 90% !important;
    padding: 7px 11px 9px;
    float: left;
  }

  .DateInput__disabled {
    width: 0px !important;
  }

  .DateInput_1 {
    #start_date_id {
      position: inherit;
      padding: 2px 13px 7px 7px;
      min-width: 200px;
    }

    #end_date_id {
      padding: 2px 22px 7px 0px;
      margin: auto;
      margin-top: 5px;
      width: 0px !important;
    }
  }

  .flightSearch .DateInput_1 {
    #start_date_id {
      padding: 52px 2px 7px 7px !important;
      min-width: 130px !important;
    }
  }

  .flightSearch .DateInput_1 {
    #return_start_date_id {
      padding: 10px 2px 7px 7px !important;
    }
  }

  .selected #end_date_id {
    margin-top: -10px !important;
  }

  .selected .DateRangePickerInput {
    padding-top: 0px !important;
  }

  .DateInput_input::placeholder {
    font-size: 16px !important;
    font-weight: normal !important;
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 400;
    color: $searchtextcolor !important;
    padding: 0px 11px 9px;
  }

  .DateRangePickerInput,
  .DateInput_input__focused {
    border: none !important;
    width: 100%;
  }

  .DateInput {
    line-height: 40px !important;
    // width: 50% !important;
    float: left;
  }
}

.DateRangePicker {
  width: 80%;
  height: 30px;
}

.DateInput_fang {
  bottom: 70px !important;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover,
.CalendarDay__default:hover {
  background: #00aeef !important;
  border: none !important;
  color: #fff !important;
  border-radius: 3px;
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover,
.CalendarDay__hovered_span,
.CalendarDay__hovered_span:active,
.CalendarDay__hovered_span:hover,
.CalendarDay__selected_span {
  background: #e5f7fb !important;
  border: none !important;
  color: #000 !important;
}

.CalendarDay__default {
  border: none !important;
}

.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
  background: #fff !important;
  color: #cacccd !important;
  border: none !important;
}

.DateRangePickerInput {
  height: 10px;
}

.DateInput {
  width: 139px !important;
}

.DateRangePickerInput__disabled,
.DateInput_input__disabled,
.DateInput__disabled {
  background: #fff !important;
  display: none;
}

.DateRangePickerInput__withBorder {
  border: none !important;
}
.hotelMyanmarCitizen {
  width: 30%;
  float: left;
  height: 34px;
  margin-top: -36.5px;
  position: initial;
  margin-left: 160px;
  //background-color: rgba(0, 0, 0, 0.5);
  background-color: #fff;

  padding: 0px 10px;

  //border-top-left-radius: 5px;
  //border-top-right-radius: 5px;
  span {
    color: #656565;
    font-family: "Roboto", Myanmar3;
    font-weight: 400;
    font-size: 14px;
    margin: auto;
  }

  .MuiFormControlLabel-root {
    margin-left: 5px;
    margin-right: 5px !important;
  }

  .MuiSvgIcon-root {
    font-size: 1rem !important;
    // color: #fff !important;
    color: #00aeef !important;
  }
}

.MuiFormControlLabel-root.Mui-disabled {
  cursor: not-allowed !important;
  color: #e4e4e4 !important;
}

.myanmarCitizen {
  width: 30%;
  float: left;
  margin-top: -36.5px;
  position: initial;
  //margin-left: 160px;
  //background-color: rgba(0, 0, 0, 0.5);
  background-color: #fff;
  border-right: 1px solid #e4e4e4;
  // border-top-left-radius: 4px;

  padding: 0px 10px;

  //border-top-left-radius: 5px;
  //border-top-right-radius: 5px;
  span {
    // color: #656565;
    font-family: "Roboto", Myanmar3;
    font-weight: 400;
    font-size: 14px;
    margin: auto;
  }

  .MuiFormControlLabel-root {
    margin-left: 5px;
    margin-right: 5px !important;
  }

  .MuiSvgIcon-root {
    font-size: 1rem !important;
    // color: #fff !important;
    //color: #00aeef !important;
  }
}

.bgKbz .myanmarCitizen .MuiSvgIcon-root,
.bgKbz .MuiRadio-colorSecondary.Mui-checked .MuiSvgIcon-root,
.bgKbzContainer .myanmarCitizen .MuiSvgIcon-root {
  font-size: 1rem !important;
  color: #0b55a8 !important;
  //color: #fff !important;
}
.bgViber .myanmarCitizen .MuiSvgIcon-root,
.bgViber .MuiRadio-colorSecondary.Mui-checked .MuiSvgIcon-root,
.bgViberContainer .myanmarCitizen .MuiSvgIcon-root {
  color: #794aff !important;

  font-size: 1rem !important;
  //color: #fff !important;
}
.bgCityReward .myanmarCitizen .MuiSvgIcon-root,
.bgCityReward .MuiRadio-colorSecondary.Mui-checked .MuiSvgIcon-root,
.bgCityReward .myanmarCitizen .MuiSvgIcon-root {
  color: #6e51a2 !important;

  font-size: 1rem !important;
  //color: #fff !important;
}

.passengerType {
  width: 30%;
  float: left;
  margin-top: -36.5px;
  position: initial;
  margin-left: 160px;
  //background-color: rgba(0, 0, 0, 0.5);
  background-color: #fff;
  border-right: 1px solid #e4e4e4;
  // border-top-right-radius: 4px;

  padding: 0px 10px;

  //border-top-left-radius: 5px;
  //border-top-right-radius: 5px;
  span {
    color: #656565;
    font-family: "Roboto", Myanmar3;
    font-weight: 400;
    font-size: 14px;
    margin: auto;
  }

  .MuiFormControlLabel-root {
    margin-left: 5px;
    margin-right: 5px !important;
  }

  .MuiSvgIcon-root {
    font-size: 1rem !important;
    // color: #fff !important;
    color: #00aeef !important;
  }
  &.hotel {
    margin: 0;
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: 0 !important;
    span {
      color: #252a2f;
    }
  }
}

#tripType {
  min-width: 130px;
  cursor: pointer;
}

.cities-popup.return-popup {
  left: 0% !important;
  padding: 5px !important;

  .MuiGrid-item {
    padding: 0px !important;
  }

  span {
    padding: 10px !important;
    display: block;
  }
}

div[id^="__lpform_"] {
  display: none;
}

@media (min-width: 1280px) {
  .MuiContainer-root {
    padding: 0px !important;
  }
}

@media (min-width: 781px) {
  .MuiContainer-root {
    padding: 0px !important;
  }

  .myanmarCitizen {
    max-width: 250px !important;
    height: 34px;
  }

  .hotelMyanmarCitizen {
    max-width: 250px !important;
  }
}

.searchBox {
  #fromAirport,
  #toAirport {
    height: 1.3875em !important;
    text-transform: capitalize;
  }
}

.passengerType {
  margin-left: 0px !important;
  // margin-left: 10px !important;
  padding: 5px 10px !important;
  width: auto !important;
  height: 24px;
  border-right: 1px solid #e4e4e4;
}

.MuiInputBase-input {
  padding: 0px !important;
}

// .Mui-focused,
// .MuiFormLabel-filled {
//   line-height: 36px !important;
// }

.MuiRadio-colorSecondary.Mui-checked {
  .MuiSvgIcon-root {
    color: #01b7f2 !important;
  }
}

.swapDestination {
  color: #707070 !important;
}

.focusedCalendar {
  margin-top: -15px;
}

.DateRangePickerInput_calendarIcon {
  padding: 0px !important;
  margin: auto !important;
}

.bgHomeService {
  background-color: #ffffff;

  .homeContainer {
    height: auto !important;
    max-width: unset;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
  }
}

.busHomeService {
  // margin-top: 132%;
  .homeContainer {
    height: auto !important;
    max-width: unset;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
  }
}

.book-now-button {
  width: 200px !important;
  height: 50px !important;
  display: block !important;
  margin: 40px auto 0 !important;
  border: solid 2px #f7696b !important;
  border-radius: 25px !important;

  span {
    font-family: "Roboto", Myanmar3 !important;
    font-weight: bold;
    color: #f7696b !important;
    text-transform: capitalize;
  }
}

.website-ads {
  width: 130%;
}

@media (min-width: 1300px) {
  .airport-popup {
    margin-left: 20% !important;
  }
  .airport-popup-flight {
    margin-left: 20% !important;
  }
}

@media (max-width: 780px) {
  .passengerType {
    display: none !important;
  }

  .newMenuList {
    display: none !important;
  }

  .footer-socialDiv {
    width: 170px;
    text-align: center;
    margin: auto;
    display: block !important;
  }

  .footer-app .grid-footer:first-child {
    width: 170px;
    margin: auto;
    display: block;
    text-align: center;
  }

  .appDownload {
    a {
      display: block;
    }

    a img {
      margin-right: 0;
    }

    text-align: center;
    flex-direction: column;
    justify-content: center;
  }

  .website-ads {
    height: 170px;
    width: 300px !important;
  }

  .MuiCard-root.confirmationBox.covidAlertBox {
    top: 50% !important;
    max-width: 100vw !important;
    left: 0% !important;
  }

  .MuiCard-root.covidAlertBox.MuiPaper-elevation1 {
    box-shadow: none !important;
  }
}

@media (min-width: 781px) {
  .myanmarCitizen,
  .passengerType {
    width: unset;
  }

  .hotelMyanmarCitizen {
    width: unset;
  }

  .searchBox {
    flex-wrap: nowrap;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }
}

@media (min-width: 781px) {
  .swapDestination {
    margin-top: 25px !important;
    margin-left: -40px !important;
    // position: absolute;
    float: left;
    cursor: pointer;
  }

  .flightSearch .swapDestination#swapDestinationIcon {
    // margin-top: 25px !important;
    margin-left: -19px !important;
    margin-right: 0 !important;
    // position: absolute;
    float: left;
    cursor: pointer;
  }

  .myanmarCitizen,
  .passengerType {
    width: unset !important;
  }

  .hotelMyanmarCitizen {
    width: unset !important;
  }

  .hotelSearchDiv {
    .searchRadioGroup {
      display: flex;
      justify-content: flex-start;
      position: relative;
      bottom: 1px;
      margin-left: 45px;
    }
  }

  .search {
    .searchRadioGroup {
      display: flex;
      justify-content: center;
      position: relative;
      bottom: 1px;
      margin-left: -18px;
    }
  }
}

.airport-popup .MuiGrid-item.airportListTitle {
  font-weight: bold;
  pointer-events: none;
  color: #00aeef;
}

.airport-popup-flight .MuiGrid-item.airportListTitle {
  font-weight: bold;
  pointer-events: none;
  color: #00aeef;
  font-size: 16px !important;
}

.airport-popup-flight .MuiGrid-item .airportListDiv {
  display: flex;
  flex-direction: row;
  padding: 7px 0px;
  .airportListImg {
    width: 10px;
    height: 15px;
    padding-right: 12px;
  }
  .listImg {
    width: 20px;
    height: 20px;
    padding-right: 12px;
  }
  .airportListRow {
    display: flex;
    flex-direction: column;
    .airportListCountry {
      color: #8e8e8e;
      font-size: 12px;
      font-weight: 400;
    }
  }
}

.airport-popup {
  width: 700px !important;
}

.airport-popup-flight {
  width: 700px !important;
}

.home.airport-popup {
  // width: 450px !important;
  width: 950px !important;
}
.home.airport-popup-flight,
.editSearch.airport-popup-flight {
  width: 350px !important;
  height: auto !important;
  max-height: 340px !important;
  // width: 950px !important;
}

.airport-popup,
.airport-popup-flight,
.cities-popup {
  width: 600px;
  margin-left: 12%;
  padding: 10px;
  margin-bottom: 15px;
  position: relative;

  .MuiGrid-item {
    padding: 7px 12px;
    font-size: 14px !important;
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 400;
  }
}

.cities-popup {
  .MuiGrid-item:hover {
    color: #00aeef;
    cursor: pointer;
  }
}

.airport-popup,
.airport-popup-flight {
  margin-left: 0% !important;
}

.top.airport-popup:after,
.top.airport-popup-flight:after,
.top.cities-popup:after {
  content: "";
  transform: rotate(-45deg);
  background: #01b7f2;
  position: absolute;
  border: 1px solid #e6e7e8;
  z-index: -1;
  left: 15%;
  margin-left: -12px;
  width: 26px;
  height: 14px;
  overflow: hidden;
  margin-top: -3px;
}

.top.cities-popup.return-popup:after {
  margin-top: -7px !important;
}

.bottom.airport-popup:after,
.bottom.airport-popup-flight:after,
.bottom.cities-popup:after {
  content: "";
  width: 26px;
  height: 14px;
  transform: rotate(-45deg);
  background: #01b7f2;
  position: absolute;
  border: 1px solid #e6e7e8;
  z-index: -1;
  top: -3px;
  left: 15%;
  position: absolute;
}

.cities-popup {
  width: 200px !important;
  left: -30% !important;
  margin-left: 0% !important;
}

.flightType {
  border: 1px solid rgba(0, 0, 0, 0.25);
  // background-color: #ebecf0;
  color: #231f20;
  border-radius: 4px;
  // padding: 7px 25px;
  padding: 7px 10px;
  width: 80px;
  float: left;
  font-size: 14px;
  line-height: 15px;
  font-family: "Roboto", Myanmar3;
  font-weight: 400;
  text-align: center;
  cursor: pointer;
  margin-right: 10px;
}

.flightType.active {
  //background-color: #01b7f2 !important;
  //background-color: var(--mui-palette-primary-main);
  color: #231f20 !important;
  // color: #fff !important;
}

.flightType.disabled {
  cursor: not-allowed;
  opacity: 0.4;
}

.MuiInputBase-root {
  color: #231f20 !important;
  line-height: normal !important;
}

.selectedAirport {
  color: #000000 !important;
  line-height: 30px;
  padding-left: 10px;
  line-height: 30px;
  padding-left: 10px;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 400;
  font-size: 14px;
}

.MuiInput-underline:before,
.MuiInput-underline:after {
  border-bottom: none !important;
}

.divFeaturedIn {
  background-color: #f7f7f7 !important;
  padding-top: 30px !important;
  margin-top: 0px !important;
  padding-bottom: 40px;
  background-color: #f7f7f7;

  .container {
    width: 100%;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
  }

  .col {
    padding: 0 5px;
    margin: 0 auto;
  }
}

.featuredInContainer {
  margin-top: 25px;
  .col {
    @media (min-width: 781px) {
      min-width: 140px;
    }
  }
}

.divAirlinePartner {
  padding-top: 30px !important;
  background-color: #f7f7f7;

  //background-image: url("../images/airline-bg.jpg");
}

.busAirlinePartner {
  padding-top: 30px !important;
  background-color: #f7f7f7f7;
  // margin-top: 52px !important;
  margin-top: 0% !important;
}

.titleSlider {
  // font-weight: normal;
  color: #00b7f0;
  // font-size: 1.267em !important;
  // line-height: 50px;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: bold;
  font-size: 16px;
  display: block;
}

.airlinePartner,
.airlineFeatured {
  display: block;
  text-align: center;
  font-size: 30px !important;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 500 !important;
  color: #000;
}

.footer {
  color: white;
  background-color: #2e2e2e;
  min-height: 50px;

  & > .container {
    margin: 0;
    width: 100%;
    max-width: unset;
  }

  .container .MuiGrid-container,
  .footer-contact-us {
    max-width: 1200px;
    width: 100%;
    margin: 30px 0px;
    justify-content: space-around;
    border-bottom: 1px solid #656565;
    justify-content: left;
  }

  .titleSlider {
    margin-bottom: 20px;
  }
  .contact-us-time {
    border: 1px solid #00aeef;
    border-radius: 10px;
    padding: 2px 5px;
    font-size: 11px;
    margin-left: 10px;
  }
  .cc-working-day {
    color: #fb7304;
    font-weight: bold;
    font-size: 14px;
    margin-left: 10px;
  }
  .cc-phone-no {
    font-weight: bold;
    margin-left: 10px;
  }
}

.footer-contact-info {
  display: flex;
  flex-direction: column;
  span {
    float: left;
  }
  .footer-cc-time {
    display: flex;
  }
}

.footer-gridbox {
  display: flex;
  justify-content: left;
  align-items: center;
  height: 50px;
  font-family: "Roboto", Myanmar3;
  font-size: 12px;
}

.grid-footer a {
  &:hover {
    color: #00b7f1;
  }
}

.grid-footer:last-child {
  justify-content: center;

  .titleSlider {
    text-align: left;
    padding-left: 10px;
  }
}

.grid-footer:nth-child(4) {
  & > div > img {
    width: 180px;
    padding: 15px 0 15px;
  }

  & > div > div {
    display: flex;

    div:first-child {
      margin-right: 15px;
    }
  }

  & div > div span {
    display: block;

    &:first-child {
      font-size: 14px;
      padding-bottom: 5px;
    }

    &:not(:first-child) {
      font-size: 16px;
      font-weight: bold;
    }
  }
}

.grid-footer:last-child {
  // > div {
  //   width: 170px;
  // }
  display: flex;
  width: 230px;
}
.grid-footer:nth-child(2) {
  padding-left: 100px;
}

.grid-footer:nth-child(3),
.footer-copy-logo .footer-gridbox:nth-child(2) {
  display: flex;
  width: 230px;
  width: 100%;
  padding-left: 130px;
}

.footer-bar {
  display: flex;
  align-items: center;

  .MuiFormControl-marginNormal {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

@media (max-width: 780px) {
  .airlinePartner {
    margin-top: 30px;
  }
}

//Medium break points
@media (max-width: 960px) {
  .divAirlinePartner .bgWhite {
    flex-wrap: wrap;
    justify-content: center;
  }

  .busAirlinePartner .bgWhite {
    flex-wrap: wrap;
    justify-content: center;

    div {
      width: 30%;
    }
  }

  .footer .container .MuiGrid-container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-width: 959px) {
  .grid-footer:nth-child(4) > div > img {
    padding-top: 0 !important;
  }

  .grid-footer:last-child {
    justify-content: flex-start;

    .titleSlider {
      text-align: left;
    }
  }
}

.footer-textfield input {
  width: 230px;
  height: 45px;
  border: 1px solid #2e2e2e;
  border-radius: 25px;
  background: white;
  outline: none;
  padding-left: 15px !important;
  margin-right: 15px;
}

.footer-subscribe {
  height: 45px;
  width: 120px;
  vertical-align: middle;
  border-radius: 25px !important;
  border: 1px solid white !important;
  margin-top: 5px;

  span {
    color: white;
    text-transform: capitalize;
  }
}

.footer-bottom {
  width: 100%;
  height: 210px;
  display: inline-block;
  overflow: hidden;
  table {
    width: 80%;
  }
  td {
    font-size: 14px;
    font-family: "Roboto", Myanmar3 !important;

    a {
      color: #fff !important;
      line-height: 20px;
    }

    a:hover {
      color: rgb(0, 174, 239) !important;
      //color: #94dbeb !important;
    }
  }

  .row {
    display: flex;
  }

  .col {
    padding-right: 10px;
    padding-bottom: 10px;

    img {
      width: 70px;
    }
  }
}

.bgWhite {
  background-color: $white;
}

.divAirlinePartner {
  .container {
    width: 100%;
  }

  .bgWhite {
    display: flex;
    justify-content: space-around;
    padding: 0 75px;
    margin-bottom: 20px;
    // margin-top: 60px;
    margin-top: 30px;
    background-color: #f7f7f7;
  }
}

.availableAirline {
  background-color: #f7f7f7;
  align-items: center;
  justify-content: center;
  text-align: center;

  .domesticTitle {
    font-family: "Poppins", Myanmar3;
    font-weight: 500;
    color: #000;
    font-size: 30px;
    padding: 0px 0px 20px 0px;
  }
  .title {
    font-family: "Poppins", Myanmar3;
    font-weight: 500;
    color: #000;
    font-size: 30px;
    padding: 15px 0px 20px 0px;
  }
  img {
    margin: 0px 24px 0px 24px;
  }
  .seeMoreContainer {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .seeMoreText {
    color: rgba(0, 172, 246, 1);
    font-size: 16px;
    font-family: "Poppins", Myanmar3;
    font-weight: 500;
    margin-top: 5px;
  }
  .arrowColor {
    color: rgba(0, 172, 246, 1);
    padding-top: 8px;
  }
}

.busAirlinePartner {
  .container {
    width: 100%;
  }

  .bgWhite {
    display: flex;
    justify-content: space-around;
    padding: 0 75px;
    margin-bottom: 20px;
    //margin-top: 5px;
    margin-top: 60px;
    background-color: #f7f7f7;
  }
}

.maillist {
  color: $white;
  font-size: 14px;
  font-weight: normal;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 400;
  font-style: italic;
}

.contact-details {
  padding-bottom: 20px;
  padding-top: 10px;
}

.departfrom {
  width: calc(33.3333% - 39.99996px);
  max-width: calc(33.3333% - 39.99996px) !important;
  min-width: calc(33.3333% - 39.99996px);
  position: relative !important;

  .MuiTextField-root {
    margin-top: 20px !important;
    width: 100%;
  }

  .MuiInputBase-root {
    font-size: 16px !important;
    // font-weight: bold !important;
    font-family: "Roboto", Myanmar3 !important;
    margin-top: 15px !important;
    width: 100%;
    padding: 0px;
    label {
      margin-top: 5px;
    }
  }

  .MuiInputLable-animated {
    margin-top: -5px !important;
  }
}

@media (min-width: 780px) {
  .flightSearch .departfrom {
    // max-width: calc(33.34% - 40px) !important;
    // min-width: calc(33.34% - 40px) !important;
    max-width: calc(33.36% - 40px) !important;
    min-width: calc(33.36% - 40px) !important;
    max-height: 73px !important;
  }

  .flightSearch .btnSearch {
    margin-top: 0px !important;
  }

  .flightSearch.hasReturn .departfrom#ArrivalGrid,
  .flightSearch.hasReturn .departfrom#DepartureGrid,
  .flightSearch.hasReturn .departfrom#DateRangeDiv {
    max-width: calc(25% - 30px) !important;
    min-width: calc(25% - 30px) !important;
  }

  .flightSearch.return .departfrom#ArrivalGrid,
  .flightSearch.return .departfrom#DepartureGrid,
  .flightSearch.return .departfrom#DateRangeDiv {
    max-width: calc(33.36% - 40px) !important;
    min-width: calc(3.36% - 40px) !important;
    max-height: 73px !important;
  }

  .flightSearch.hasReturn .btnSearch {
    margin-top: -0.8px !important;
  }
}

.returnTrip {
  padding-left: 10px !important;
}

.MuiFormControl-root {
  label {
    margin-top: -12px;
  }
}

.MuiFormControl-root {
  .MuiInputLabel-shrink {
    transform: translate(0, -5.5px) scale(0.85) !important;
  }
}

.dividerAiport {
  height: 10px;
  padding: 10px 0px;
}

.departureDate {
  font-size: 12px;
  font-family: "Roboto", Myanmar3;
  font-weight: 400;
  color: #a5a7a6 !important;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.busOperatorLogo {
  .imgContainer {
    display: inline-block;
    width: 184px !important;
    max-width: 184px !important;

    img {
      max-width: 100%;
      width: 100% !important;
      margin: -15px 0px;
    }
  }
}

.block {
  display: block !important;
  font-size: 12px;
  font-family: "Roboto", Myanmar3;
  font-weight: 400;
  color: #a5a7a6 !important;
  opacity: 1;
  transform: translate(0, 1.5px) scale(1);
  transform-origin: top left;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  margin-top: 8px;
}

.departFlight {
  font-family: "Poppins", Myanmar3 !important;
  font-weight: 400;
  float: left;
  margin-left: 8px;
  width: 37%;
  min-width: 100px;
  margin-top: 6px;
  max-height: 30px;
  height: 22px;
}

.returnFlight {
  float: right;
  margin-left: 50px;
  width: 45%;
}

.hide {
  display: none !important;
}

.mmBusLogo {
  margin-top: 5px;
}
.mmBusLogo img,
.mmBusLogo span {
  font-size: 12px !important;
  float: right !important;
}

.mmBusLogo img {
  width: 50px !important;
  // New MM Bus Ticket Logo adjustments
  margin-top: 18px;
  margin-left: 5px;
}

.mmBusLogo span {
  //color: #fff;
  color: #000;
  font-weight: bold;
  line-height: 70px !important;
}

@media (min-width: 700px) {
  .mobile-from-to {
    display: none !important;
  }
}

.DateInput_input__focused::placeholder {
  opacity: 0 !important;
}

.alice-carousel__stage-item {
  opacity: 0.5;
  width: 200px;
}

.alice-carousel__stage-item:hover {
  opacity: 1 !important;
}

.airlineImg {
  width: 184px;
  height: 61px;
  padding: 20px 0;
}

.pull-left {
  float: left;
}

.clickAwayDiv {
  width: 100%;
  float: left;
  height: 100%;
}

.flightSearch .clickAwayDiv {
  width: 88% !important;
}

.arrow-left {
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  position: absolute;
  top: 28px;
  line-height: 0.78;
  left: 25px;
  border-right: 9px solid #4b515d;
}

.arrow-right {
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  position: absolute;
  top: 28px;
  line-height: 0.78;
  right: 25px;
  border-left: 9px solid #4b515d;
}

.DayPicker_weekHeader {
  font-family: "Roboto", Myanmar3;
  font-weight: 400;
  font-size: 14px !important;
}

.DateRangePicker_picker {
  border-radius: 5px;
  left: -180px !important;
  bottom: 62px !important;
  z-index: 1 !important;
}

.checkReturnDate {
  margin-top: 13px !important;
  margin-left: 240px !important;
  z-index: 999;
  position: absolute !important;
  height: auto !important;
}

.selectedCheckReturn {
  margin-top: 23px !important;
  margin-left: 240px !important;
  z-index: 999;
  position: absolute !important;
  height: auto !important;
}

.btnBlueSearch {
  display: none !important;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.pull-left {
  float: left;
}

.homeMenu,
.chooseReturnDate {
  opacity: 0 !important;
}

.datepicker {
  margin: auto;
  max-height: 74px;
  position: relative;
}

.textRight {
  text-align: right !important;
}

.dateRange {
  width: -webkit-calc(0.333333 * (100% - 120px));
  width: -moz-calc(0.333333 * (100% - 120px));
  width: calc(0.333333 * (100% - 120px));
  max-width: calc(0.333333 * (100% - 120px)) !important;
  min-width: calc(0.333333 * (100% - 120px));
  //margin: auto 0px !important;
}

.DateRangePickerInput_arrow {
  display: none !important;
}

.calendarIcon {
  // position: absolute;
  z-index: 30;
  line-height: 50px;
  margin: 24px 0px;
  width: 26px;
  height: 26px;
  float: left;
}

.flightSearch .calendarIcon {
  margin: 22px 0px;
}

.selected-calendar {
  margin-top: 24px !important;
}

.dividerContainer {
  margin: 5px !important;
  margin-bottom: 25px !important;
  border-color: #1e1e1e !important;
}

.loadingWrapper {
  margin: 20px auto;
  width: 889px !important;
  text-align: left;
  background-color: #fff;
  padding: 20px;
}

.btnDisable {
  //background-color: rgb(196, 112, 22) !important;
  opacity: 0.7;
  pointer-events: none;
}

.btnToolDisable {
  opacity: 0.7;
  pointer-events: none;
}

.noborder {
  border: none !important;
}

.airport-popup,
.airport-popup-flight {
  ::-webkit-scrollbar {
    width: 3px;
  }

  .scroll {
    background-color: #fff;
    width: 100%;
    overflow-y: scroll;
  }

  .scroll::-webkit-scrollbar {
    width: 6px;
    background-color: #fff;
  }

  .scroll::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fff;
    height: 30px;
  }

  .scroll::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 10px;
  }

  // ::-webkit-scrollbar-track {
  //     -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  // }

  // ::-webkit-scrollbar-thumb {
  //   background-color: darkgrey;
  //   outline: 1px solid slategrey;
  // }
  scrollbar-width: normal !important;
  scrollbar-color: red !important;

  .MuiGrid-container {
    // display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(8, auto);
    max-height: 290px !important;
    min-height: 40px !important;
    overflow: auto;
    scrollbar-width: normal !important;
    scrollbar-color: red !important;

    ::-webkit-scrollbar {
      width: 12px;
      height: 12px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: red;
    }

    ::-webkit-scrollbar-track {
      background-color: green;
    }

    .MuiGrid-container ::-webkit-scrollbar {
      width: 12px;
      height: 12px;
    }

    .MuiGrid-grid-xs-4 {
      max-width: 100% !important;
    }
  }
}

// .airport-popup-auto{
//   .MuiGrid-container {
//   //height: auto !important;
//   }
// }

.inAppHeaderLogo {
  width: 150px;
  display: block;
  margin-left: 15px;
}

.pageInAppError {
  width: 90%;
  height: 100vh;
  display: block;
  margin: 0 auto !important;
  text-align: center;

  .title {
    font-family: "Roboto", Myanmar3;
    font-weight: 700;
    font-size: 40px;
  }

  button {
    font-family: "Roboto", Myanmar3;
    background-color: #2196f3;
    padding: 10px 15px;
    color: #fff;
    margin-left: 20px;
  }
}

.page404 {
  width: 90%;
  height: 100vh;
  display: block;
  margin: 0 auto !important;
  text-align: center;

  .title {
    font-family: "Roboto", Myanmar3;
    font-weight: 700;
    font-size: 80px;
  }

  .warning {
    font-family: "Roboto", Myanmar3;
    font-weight: 500;
    font-size: 30px;
    color: #919191;
    display: inline-block;
    margin-bottom: 30px;
  }

  button {
    font-family: "Roboto", Myanmar3;
    background-color: #2196f3;
    padding: 10px 15px;
    color: #fff;
    margin-left: 20px;
  }

  button:hover {
    background-color: #2196f3;
  }
}

.pageHeaderLogo {
  width: 160px;
  display: block;

  img {
    height: 24px;
    padding-right: 3px;
  }
}

.CalendarDay__default.CalendarDay__today {
  color: #0041ff !important;
  border: 2px solid #a7a7a7 !important;
  border-radius: 3px;
}

.MuiMenuItem-root {
  overflow: inherit !important;

  .searchBox {
    .MuiInputBase-input {
      width: 90% !important;
    }
  }
}

.languageDiv {
  box-shadow: 0 5px 11px #ccc;
  border: 1px solid hsla(0, 0%, 50.2%, 0.08);
  text-align: center !important;
  padding: 0rem 0.5rem !important;
}

.languageButtonEng {
  text-align: center;
}

.languageButton {
  text-align: center;
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.languageButtonMya {
  margin-right: 1rem !important;
}

.disableButton {
  opacity: 0.3 !important;
  cursor: default !important;
}

.myanmarFont,
span,
label,
.MuiFormLabel-root,
.departFlight {
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 400;
}

.homeAgeSelect {
  width: 90%;
  float: left;
  margin: 5px 0px;
}

.pasengerTypeCard {
  max-width: 260px;
  padding: 20px;
  left: 25%;
  z-index: 1000;

  .ageSelect {
    width: 90% !important;
  }
}

#navTravelServices {
  position: relative;

  &:hover {
    .travelServicePassengerTooltip {
      display: block;
    }
  }
}

.travelServicePassengerTooltip {
  position: absolute;
  top: 75%;
  left: -65%;
  z-index: 1000 !important;
  padding-top: 23px;
  @media (min-width: 601px) {
    display: none;
  }
}

.travelServicesCard {
  max-width: 400px;
  min-width: 310px;
  padding: 20px;
  // left: 25%;
  z-index: 1000;
  margin-top: 5px;
  img {
    top: 13px;
    position: absolute;
    left: 45%;
  }
  .servicesWrapper {
    display: flex;
    gap: 25px;
    a {
      font-family: "Roboto", Myanmar3 !important;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      color: #000;
      svg {
        margin-right: 10px;
      }
    }
    a:hover {
      color: rgb(0, 174, 239);
    }
    .visaService {
      // margin-right: 20px;
    }
  }
}
.travelServicesCardMobile {
  padding: 20px;
  z-index: 1000;
  margin-top: -26px;
  img {
    position: absolute;
    top: -40px;
    left: 45%;
  }
  .servicesWrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    span {
      font-family: "Roboto", Myanmar3 !important;
      display: flex;
      align-items: center;
      font-size: 15px;
      color: #000;
      // margin-bottom: 10px;
      svg {
        margin-right: 10px;
      }
    }
  }
}

#navHotel {
  position: relative;
  &:hover {
    .hotelTooltip {
      display: block;
    }
  }
}
.hotelTooltip.clicked {
  display: none !important;
}
.hotelTooltip {
  position: absolute;
  top: 75%;
  left: -164%;
  z-index: 1000 !important;
  padding-top: 23px;
  @media (min-width: 601px) {
    display: none;
  }
}

@supports (-moz-appearance: none) {
  .hotelTooltip {
    top: 75px;
    left: -130px;
  }
}

::i-block-chrome,
.hotelTooltip {
  top: -20px;
  left: -130px;
}

.hotelCard {
  max-width: 600px;
  min-width: 550px;
  padding: 20px;
  // left: 25%;
  z-index: 1000;
  margin-top: 5px;
  img {
    top: 13px;
    position: absolute;
    left: 27%;
  }
  .servicesWrapper {
    display: flex;
    gap: 40px;
    a {
      font-family: "Roboto", Myanmar3 !important;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      color: #000;
      svg {
        margin-right: 10px;
      }
    }
    a:hover {
      color: rgb(0, 174, 239);
    }
    .hotelSub {
      // margin-right: 20px;
    }
  }
}

.react-numeric-input b:nth-child(2) i {
  background-color: #23af2e !important;
}

.react-numeric-input b:last-child i {
  background-color: #ff5757 !important;
}

.homeAgeSelect {
  width: 90%;
  float: left;
  margin: 5px 0px;
}

.pasengerTypeCard {
  max-width: 260px;
  padding: 20px;
  left: 25%;
  z-index: 1000;

  .ageSelect {
    width: 90% !important;
  }
}

.homeChooseAge {
  width: 100%;
  float: right;
  margin: 10px 0px;

  .react-numeric-input {
    width: 100%;
    padding-left: 2px;
    height: 30px;
  }

  .numeric-counter {
    padding: 0px !important;
    height: 30px;
    width: 106px;
    border: 1px solid #c7c7c7 !important;
  }

  b {
    background-color: #fff !important;
    border: none !important;
    border-right: 1px solid #c7c7c7 !important;
    border-left: 1px solid #c7c7c7 !important;
    width: 4.3ex !important;
    cursor: pointer !important;
  }
}

.passengerTooltip {
  z-index: 1000 !important;
  // display: none;
  &.hotel-detail {
    z-index: 1500 !important;
  }
}

@media (min-width: 781px) and (max-width: 1000px) {
  .myanmarCitizen {
    width: 250px;
  }

  .hotelMyanmarCitizen {
    width: 250px;
  }
}

.selected--flag--option,
.arrow-down {
  color: #fff !important;
}

.flag-select {
  margin-top: 5px;
  color: #888;
}

.homeFlag {
  float: right !important;
}

#bgBus .flag-select__btn,
#bgCar .flag-select__btn,
#bgHotel .flag-select__btn,
#bgBalloon .flag-select__btn,
.bgHome .flag-select__btn {
  color: #fff !important;
  padding-left: 0px;
  margin-left: -5px;
}

.homeFlag > .selected--flag--option {
  font-size: 16px !important;
  color: #fff !important;
}

#homeFlag .flag-select {
  float: right !important;
  margin-top: 3px !important;
}

#homeFlag .flag-select .selected--flag--option {
  font-size: 16px !important;
  color: #fff !important;
}

#homeFlag .flag-select .arrow-down {
  color: #fff !important;
}

#homeFlag button:focus {
  border-color: transparent;
  outline: none;
}

.__react_component_tooltip {
  z-index: 99999 !important;
}

//TABLE
@media (min-width: 769px) and (max-width: 1023px) {
  .homeContainer {
    // .MuiGrid-item:first-child {
    //   #homeFlag .flag-select {
    //     display: none;
    //   }
    //   button.menuIcon.nopadding {
    //     display: none;
    //   }
    // }
    .menuList {
      position: absolute;
      right: 0;
    }
  }
}

@media (min-width: 800px) {
  .deptAirportImgDiv {
    background-image: url("../images/sprite-images.png");
    background-position: 1px -91px;
    background-size: 25px;
    width: 24px;
    height: 24px;
    float: left;
    margin-top: 28px;
    margin-right: 15px;
  }

  .arrivAirportImgDiv {
    background-image: url("../images/sprite-images.png");
    background-position: 1px -117px;
    background-size: 25px;
    width: 24px;
    height: 24px;
    float: left;
    margin-top: 28px;
    margin-right: 15px;
  }

  .flightSearch .arrivAirportImgDiv {
    margin-left: 5px !important;
  }

  .calendarImgDiv {
    background-image: url("../images/sprite-images.png");
    background-position: 1px 35px;
    background-size: 25px;
    width: 24px;
    height: 24px;
    float: left;
    margin-top: 28px;
    // margin-right: 15px;
  }

  .down-arrow-img {
    background-image: url("../images/icon-sprite-min.png");
    background-position: 143px -27px;
    background-size: 220px;
    width: 24px;
    height: 24px;
    float: right;
    margin-left: 5px;
  }

  .allday-img {
    background-image: url("../images/icon-sprite-min.png");
    background-position: -6px -77px;
    background-size: 187px;
    width: 58px;
    height: 49px;
    float: right;
    margin-right: 5px !important;
  }
}

.myanTranslation {
  font-size: 18px !important;
}

.MuiDialog-paperWidthSm {
  min-width: 70% !important;
  position: relative !important;
}

.MuiDialogTitle-root {
  padding: 5px 12px !important;
}

.Component-closeButton-* {
  top: -8px !important;
  right: -5px !important;
  position: relative !important;
}

.offlineCloseIcon {
  top: -3px !important;
}

/*feature image sprite*/
.feature-img-irrawady-burma {
  background-position: 12px -10px;
  background-size: 1000px;
  width: 105px;
  height: 65px;
  float: left;
}

.feature-img-goaway {
  background-image: url("../images/features_new-min.png");
  background-position: -239px -12px;
  background-size: 1000px;
  width: 100px;
  height: 65px;
  float: left;
}

.feature-img-honeycamber {
  background-image: url("../images/features_new-min.png");
  background-position: -333px -12px;
  background-size: 1000px;
  width: 100px;
  height: 65px;
  float: left;
}

.feature-img-ncra {
  background-image: url("../images/features_new-min.png");
  background-position: -397px -93px;
  background-size: 1000px;
  width: 135px;
  height: 65px;
  float: left;
}

.feature-img-mizzima-burma {
  background-image: url("../images/features_new-min.png");
  background-position: 10px -93px;
  background-size: 1000px;
  width: 105px;
  height: 65px;
  float: left;
}

.feature-img-malay-mail-online {
  background-image: url("../images/features_new-min.png");
  background-position: -568px -26px;
  background-size: 1000px;
  width: 115px;
  height: 65px;
  float: left;
}

.feature-img-sd-asia {
  background-image: url("../images/features_new-min.png");
  background-position: -563px -96px;
  background-size: 1000px;
  width: 108px;
  height: 65px;
  float: left;
}

.feature-img-today {
  background-image: url("../images/features_new-min.png");
  background-position: -853px -96px;
  background-size: 1000px;
  width: 81px;
  height: 65px;
  float: left;
}

.feature-img-mizzima-eng {
  background-image: url("../images/features_new-min.png");
  background-position: -816px -16px;
  background-size: 976px;
  width: 127px;
  height: 65px;
  float: left;
}

.feature-img-mingalapar {
  background-image: url("../images/features_new-min.png");
  background-position: -698px -25px;
  background-size: 1000px;
  width: 119px;
  height: 65px;
  float: left;
}

.feature-img-myan-biz-today {
  background-image: url("../images/features_new-min.png");
  background-position: -254px -86px;
  background-size: 960px;
  width: 103px;
  height: 65px;
  float: left;
}

.feature-img-coconut-bkk {
  background-image: url("../images/features_new-min.png");
  background-position: -402px -163px;
  background-size: 1000px;
  width: 90px;
  height: 65px;
  float: left;
}

.feature-img-coconut-ygn {
  background-image: url("../images/features_new-min.png");
  background-position: -135px -158px;
  background-size: 1000px;
  width: 90px;
  height: 65px;
  float: left;
}

.feature-img-yangon-life {
  background-image: url("../images/features_new-min.png");
  background-position: -259px -158px;
  background-size: 1000px;
  width: 137px;
  height: 65px;
  float: left;
}

.feature-img-irrawaddy {
  background-image: url("../images/features_new-min.png");
  background-position: -450px -17px;
  background-size: 1000px;
  width: 110px;
  height: 65px;
  float: left;
}

.feature-img-travel-leisure {
  background-image: url("../images/features_new-min.png");
  background-position: -10px -155px;
  background-size: 1000px;
  width: 100px;
  height: 65px;
  float: left;
}

.feature-img-myanmar-times {
  background-image: url("../images/features_new-min.png");
  background-position: -126px -89px;
  background-size: 1000px;
  width: 142px;
  height: 65px;
  float: left;
}

.feature-img-new-light {
  background-image: url("../images/features_new-min.png");
  background-position: -126px -12px;
  background-size: 1000px;
  width: 122px;
  height: 65px;
  float: left;
}

.feature-img-tech-in-asia {
  background-image: url("../images/features_new-min.png");
  background-position: -684px -98px;
  background-size: 1000px;
  width: 142px;
  height: 65px;
  float: left;
}

/*feature image sprite*/

/*airline image sprite*/
.airline-gma {
  background-image: url("../images/AirlineIcons-01.png");
  // background-position: -200px -67px;
  background-position: 1px -85px;
  background-size: 391px;
  // width: 184px;
  // height: 75px;
  height: 60px;
  width: 184px;
  float: left;
  margin-left: 20px;
}

.airline-airkbz {
  background-image: url("../images/AirlineIcons-01.png");
  // background-position: -18px -144px;
  background-position: 183px -1px;
  background-size: 419px;
  // width: 184px;
  // height: 75px;
  height: 60px;
  width: 184px;
  float: left;
}

.airline-yangon-airway {
  background-image: url("../images/AirlineIcons-01.png");
  background-position: -4px 7px;
  //background-position: -4px 7px;
  background-size: 400px;
  width: 184px;
  height: 75px;
  float: left;
}

.airline-mna {
  background-image: url("../images/AirlineIcons-01.png");
  background-position: -203px 74px;
  background-size: 416px;
  width: 184px;
  height: 75px;
  float: left;
  margin-top: -8px;
}

.airline-mna-mobile {
  background-image: url("../images/AirlineIcons-01.png");
  background-position: -370px 74px;
  background-size: 390px;
  width: 184px;
  height: 74px;
  float: left;
  display: none;
  margin-top: -16px;
}

.airline-myp {
  background-image: url("../images/AirlineIcons-01.png");
  // background-position: -16px -280px;
  // background-position: 183px -79px;
  background-position: 182px -96px;
  background-size: 444px;
  // width: 184px;
  // height: 75px;
  height: 51px;
  width: 184px;
  float: left;
}

.airline-myp-desktop {
  background-image: url("../images/AirlineIcons-01.png");
  // background-position: -16px -280px;
  // background-position: 183px -79px;
  background-position: 182px -96px;
  background-size: 444px;
  // width: 184px;
  // height: 75px;
  height: 51px;
  width: 184px;
  float: left;
}

.airline-air-thanlwin-desktop {
  background-image: url("../images/AirlineIcons-01.png");
  background-position: 22px 51px;
  background-size: 400px;
  // width: 184px;
  // height: 75px;
  height: 51px;
  width: 184px;
  float: left;
}

.desktopLogo {
  display: flex !important;
  @media (max-width: 780px) {
    display: none !important;
  }
}

.mobileLogo {
  display: none !important;
  @media (max-width: 780px) {
    display: flex !important;
  }
}

.airline-air-thanlwin {
  background-image: url("../images/AirlineIcons-01.png");
  background-position: 22px 51px;
  background-size: 400px;
  // width: 184px;
  // height: 75px;
  height: 51px;
  width: 184px;
  float: left;
}

.airline-air-thanlwin-mobile {
  background-image: url("../images/AirlineIcons-01.png");
  background-position: 207px 75px;
  background-size: 400px;
  width: 184px;
  height: 75px;
  float: left;
  display: none;
  margin-top: -23px;
}

/*airline image sprite*/

//tablet and desktoo
@media (min-width: 781px) {
  .bgHome.MuiContainer-root {
    padding: 0px !important;
  }

  .bgHome #DepartureGrid {
    border-right: 1px solid #e4e4e4;
    // margin-right: 21px;
    // width: calc(24% - (39.99996px + 10px));
    // min-width: calc(24% - (39.99996px + 10px)) !important;
    width: 24% !important;
  }

  .bgHome #swapDestinationIcon {
    pointer-events: none;
    border: 1px solid #e4e4e4;
    border-radius: 50px;
    padding: 5px;
    margin-top: 19px !important;
    background-color: #fff;
  }

  .bgHome #ArrivalGrid {
    // width: calc(24% - (40.99996px + 10px));
    // min-width: calc(24% - (40.99996px + 10px));
    width: 24% !important;
    border-right: 1px solid #e4e4e4;
  }

  .bgHome #DateRangeDiv {
    //border-left: 1px solid #e4e4e4;
    height: 100%;
    width: 24% !important;
  }
  .bgHome .busSearch #DateRangeDiv {
    border-left: none !important;
  }
  .bgHome #tripTypeDiv {
    border-left: 1px solid #e4e4e4;
    // padding-left: 20px;
    // width: calc(24% - (39.99996px + 11px));
    // min-width: calc(24% - (39.99996px + 11px));
    // margin-right: 4px !important;
    width: 24% !important;
  }
}

@media (max-width: 780px) {
  .mdatepicker .SingleDatePicker_picker {
    position: fixed !important;
    top: 45% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    z-index: 10;
    border-radius: 3px;
  }

  #bg2 {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.3);
    overflow-y: hidden !important;
    overflow-x: hidden !important;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
  }

  .footer-bottom {
    height: auto !important;

    td {
      height: 48px;
      font-size: 16px;
    }
  }

  .footerPhoneNo {
    height: 48px;
    line-height: 45px;
  }

  .DateInput_fang {
    display: none !important;
  }

  #ArrivalGrid {
    width: 100%;
  }

  #DepartureGrid {
    width: 100%;
  }
}

@media (min-width: 781px) {
  .CalendarDay__default {
    border: none !important;
  }

  .sortingBox .MuiInput-root.mm-pull-right {
    width: 175px !important;
  }
}

.tripTypeContainer.MuiGrid-item {
  padding: 0px 0px !important;
}

.tripTypeContainer .tripTypelabel {
  padding: 7px 12px;
  display: block;
}

.tripTypeLableSelected {
  background: #e8e8e8 !important;
  border-radius: 4px !important;
}

.tripTypeLableSelected span {
  color: #000000 !important;
  font-weight: 700 !important;
}

.tripType {
  margin-right: 5px !important;
}

.tripTypePopupTooltip {
  z-index: 10 !important;
}

.tripTypePopupTooltip div {
  width: 140px !important;
}

.tripTypePopupTooltip div::after {
  margin-left: 22px !important;
}

.returnText {
  font-family: "Poppins";
  font-weight: 300 !important;
  color: #231f20 !important;
  font-size: 28px !important;
  line-height: 32px !important;
}

.stayText {
  color: #8d8d8d !important;
  font-size: 18px !important;
  line-height: 20px !important;
}

.tripTypeDiv
  .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
  margin-top: 16px !important;
}

#DateRangeDiv .calendarImgDiv {
  margin-left: 10px !important;
}

.appDownload {
  display: block !important;

  a {
    display: "inline-flex";
  }

  img {
    width: 150px;
    height: auto;
    margin-right: 20px;
  }

  #downloadAPK {
    width: 24px;
    margin-right: 0;
  }
}

.footer-app {
  margin-top: -10px !important;
}

.nationality-radio-btn {
  max-width: 73px !important;
}

.selectedDepartFlightNo {
  display: inline-block;
  width: 75%;
  font-size: 14px;
}

.privacyPolicy {
  width: 77% !important;
  font-family: "Roboto", Myanmar3 !important;
  margin: 10px auto !important;
  // min-height: 100vh;

  p {
    line-height: 22px;
  }
}

#homeFlag {
  margin-left: 90% !important;
}

.flightSearch {
  z-index: 20 !important;
  left: 10% !important;
  display: block !important;
  margin-top: 20px;
}

.flightPopup {
  //z-index: 1 !important;
  left: 15% !important;
  top: 6px !important;
  z-index: 99999;
  transform: none !important;
  //top: 10px !important;
  //transform: translate3d(4px, 60px, 0px) !important;
}

.flightNewPopup,
.flightPopupb2b {
  //z-index: 1 !important;
  // left: 15% !important;
  top: 85px !important;
  z-index: 99999;
  transform: none !important;
  //top: 10px !important;
  //transform: translate3d(4px, 60px, 0px) !important;
}

.searchBox.web-from-to {
  .flightNewPopup,
  .flightPopupb2b {
    top: 50px !important;
    left: -35px !important;
  }
}

.flightPopup.editSearch,
.flightToPopup.editSearch {
  top: 150px !important;
}

.flightNewToPopup.editSearch {
  top: 85px !important;
  left: 0 !important;
}
.flightNewPopup.editSearch {
  top: 85px !important;
  .editSearch.airport-popup-flight {
    left: 0 !important;
  }
}

.flightToPopup {
  left: 15% !important;
  top: 6px !important;
  z-index: 99999;
  transform: none !important;
  //top: 10px !important;
  //transform: translate3d(209px, 60px, 0px) !important;
}

.flightPopupb2b.editSearch,
.flightToPopupb2b.editSearch {
  top: 85px !important;
  left: -20% !important;
}
.flightToPopupb2b.editSearch .toAirport.airport-popup-flight {
  left: 12% !important;
}
.flightPopupb2b.editSearch .fromAirport.airport-popup-flight {
  left: 45px !important;
}

.flightNewToPopup,
.flightToPopupb2b {
  // left: 30% !important;
  top: 85px !important;
  z-index: 99999;
  transform: none !important;
  //top: 10px !important;
  //transform: translate3d(209px, 60px, 0px) !important;
}

.searchBox.web-from-to {
  .flightNewToPopup,
  .flightToPopupb2b {
    top: 45px !important;
    left: -62px !important;
  }
}

.flightPopupAuto {
  z-index: 10 !important;
  left: 15% !important;
  top: 40px !important;
  height: 400px !important;
  transform: translate3d(209px, 84px, 0px) !important;
}

.flightToPopupAuto {
  z-index: 10 !important;
  left: 12% !important;
  top: 40px !important;
  height: 400px !important;
}

// .top.home.fromAirport.airport-popup{
//   margin-top:-40px !important;
// }
.homeContainer .flightSearch .DateInput_input {
  font-size: 16px !important;
}

.contactUsTitle {
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 10px;
}

.supportTitle {
  font-family: "Poppins", Myanmar3 !important;
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 10px;
}

.contactUsGet {
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 10px;
}

.leaveMessage {
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 500;
  font-size: 12px;
  // text-transform: uppercase;
  margin-bottom: 10px;
  line-height: 20px;
}

.contactUsBox {
  padding-left: 50px;

  .fieldTitle {
    font-family: "Roboto" !important;
    font-size: 13px !important;
    font-weight: 500;
  }

  .errMessage {
    font-weight: 400;
    color: red;
    font-size: 12px;
    font-family: "Roboto" !important;
  }
}

.customerContainer .contactUsFieldBox {
  border: 1px solid #e6e7e8;
  border-radius: 4px;
  width: 100%;

  .MuiInputBase-input {
    height: 30px;
    padding: 5px 10px !important;
    font-family: "Roboto" !important;
    font-size: 14px !important;
    border: none !important;
  }
}

.customerContainer .contactUsMessageBox {
  border: 1px solid #e6e7e8 !important;
  border-radius: 4px;
  width: 100%;

  .MuiInputBase-input {
    padding: 5px 10px !important;
    font-family: "Roboto" !important;
    font-size: 14px !important;
    border: none !important;
  }
}

.btnSendMessage {
  background-color: #f7911e !important;
  border-radius: 4px;
  color: #fff !important;
  font-family: "Roboto", Myanmar3 !important;
  font-size: 14px !important;
  text-transform: capitalize !important;
  height: 45px;
  width: 150px;
}

.btnMessageBox {
  text-align: right;
  margin: 15px 0px !important;

  .MuiCircularProgress-root {
    color: #fff !important;
    width: 30px !important;
    height: 30px !important;
  }
}

.contactUsAddressBox {
  margin-top: 10px !important;
}

.officeAddressBox {
  padding-bottom: 30px;
  font-family: "Roboto", Myanmar3 !important;

  div:nth-child(3),
  div:nth-child(5) {
    font-size: 16px;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  :nth-child(4) {
    padding-bottom: 20px;
  }

  span {
    font-size: 14px !important;
  }
}

.contactUsContainer {
  background-color: #fff !important;
  margin: 0px !important;
  @media (max-width: 780px) {
    padding-block: 20px;
    .leftArrowMenu {
      display: none !important;
    }
    .rightArrowMenu {
      display: none !important;
    }
  }
}

.visitUs {
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
}

.officeAddress {
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 400;
  font-size: 14px;
  margin: 10px 0px;
  text-transform: capitalize !important;
  line-height: 20px;

  a {
    text-decoration: none;
    color: #000 !important;
    line-height: 30px;
  }
}

.officeAddress.emailAddress {
  text-transform: lowercase !important;
}

.officeAddressTitle {
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 500;
  font-size: 16px;
  margin: 20px 0px 10px 0px;
}

.contactUsImg {
  width: 20px;
  height: 20px;
  padding-left: 10px;
}

.supportImage {
  width: 35px;
  height: 35px;
  margin-bottom: 10px;
}

.MuiSnackbar-root {
  left: 7% !important;

  .MuiSnackbarContent-root {
    background-color: #4caf50 !important;
  }

  .MuiSvgIcon-root {
    color: #fff !important;
  }
}

.searchErrMessage .contactUsFieldBox,
.searchErrMessage .contactUsMessageBox {
  border: none !important;

  .MuiInputBase-root {
    border: 1px solid #ff0000 !important;
    border-radius: 4px;
  }
}

.searchErrMessage {
  input {
    border: 1px solid #ff0000 !important;
  }

  .MuiInputBase-input {
    border: 1px solid #ff0000 !important;
  }

  .errMessage {
    font-family: "Roboto", Myanmar3;
    font-weight: 400;
    color: #ff0000;
    font-size: 12px;
  }
}

.nopadding-bottom {
  padding-bottom: 0px !important;
}

.contactUsMenu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.contactUsMenu a {
  color: #000 !important;
  padding: 19px 15px;
  font-family: "Roboto", Myanmar3 !important;
  float: right;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}

.contactUsMenu #contactUsTravel {
  position: relative;
  &:hover {
    .travelServiceHeaderTooltip {
      display: block;
    }
  }
}

.travelServiceHeaderTooltip {
  position: absolute;
  top: 60%;
  left: -65%;
  z-index: 1000 !important;
  padding-top: 23px;
  @media (min-width: 601px) {
    display: none;
  }
}

.travelServicesHeaderCard {
  max-width: 400px;
  min-width: 310px;
  // left: 25%;
  z-index: 1000;
  margin-top: 5px;
  img {
    top: 13px;
    position: absolute;
    left: 45%;
  }
  .servicesHeaderWrapper {
    display: flex;
    gap: 25px;
    a {
      font-family: "Roboto", Myanmar3 !important;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      color: #000;
      svg {
        margin-right: 10px;
      }
    }
    a:hover {
      color: rgb(0, 174, 239);
    }
    .visaService {
      // margin-right: 20px;
    }
  }
}

.contactUsMenu #contactUsHotel {
  position: relative;
  &:hover {
    .hotelHeaderTooltip {
      display: block;
    }
  }
}
.hotelHeaderTooltip {
  position: absolute;
  top: 60%;
  left: -150%;
  z-index: 1000 !important;
  padding-top: 23px;
  @media (min-width: 601px) {
    display: none;
  }
}

.mmContactUsMenu a {
  padding: 12px !important;
}

@media (max-width: 768px) {
  .contactUsBox {
    padding-left: 0px !important;
  }

  .contactUsAddressBox {
    padding-left: 20px !important;
  }

  .aboutUsImgContainer {
    padding: 30px 0px !important;

    img {
      width: 100% !important;
    }
  }
}

.aboutUsContent {
  font-size: 14px !important;
}

.aboutUsTitle {
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 600;
  font-size: 24px;
  margin: 30px 0px;
}

.aboutUsProductsTitle {
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 600;
  font-size: 18px;
}

.aboutUsProducts {
  text-align: center;
  padding-bottom: 30px !important;
  padding-top: 10px !important;
}

.aboutUsProductsDiv {
  padding: 20px 30px;
  font-family: "Roboto", Myanmar3 !important;

  p {
    font-size: 14px;
    font-family: "Roboto", Myanmar3 !important;
    // min-height: 110px !important;
    height: 150px;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
  }

  img {
    width: 80px;
  }

  a {
    text-decoration: none !important;
  }
}

.aboutUsProductAds {
  max-width: 800px;
  margin: auto;

  div {
    margin: auto;
  }
}

.btnAboutUsBookNow {
  background-color: #f7911e !important;
  width: 220px;
  border-radius: 4px !important;
  color: #fff !important;
  height: 50px !important;
  border: none !important;
  text-transform: capitalize !important;

  span {
    font-weight: 600;
    font-family: "Roboto", Myanmar3 !important;
  }
}

.aboutUsImgContainer {
  // padding: 60px 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 365px;
  }
}

.signatureContent {
  padding-top: 50px;
}

#mobileFlightDateRangeLableDiv {
  top: -40px !important;
  position: absolute !important;
  padding-left: 15px !important;
  background: #fff;
  min-height: 50px;
  width: 97% !important;
  padding-top: 10px !important;
  border-radius: 4px !important;
}

.dateLableDividerDiv {
  width: 96% !important;
  margin-top: 10px !important;
  margin-right: 20px !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
  height: 1px !important;
  border: none !important;
}

#mobileFlightDateRangeLableDiv .flightType {
  width: auto !important;
  text-align: center !important;
}

.CalendarDay__default.CalendarDay__today {
  color: #484848 !important;
  border: none !important;
  border-radius: 6px !important;
}

.flightSearch .CalendarDay__selected.CalendarDay__today,
.editSearch .CalendarDay__selected.CalendarDay__today,
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  outline: none !important;
  color: #fff !important;
}

@media (max-width: 780px) {
  .DayPicker {
    min-width: 370px !important;
  }

  #mobileFlightDateRangeLableDiv {
    top: -230px !important;
    z-index: 100 !important;
    margin-left: -17px !important;
    width: 100% !important;
    padding-left: 17px !important;
    padding-right: 17px !important;
  }
}

.mobileFlightDateRangeLableDiv {
  display: none !important;
}

.confirmationBox.covidAlertBox {
  padding: 50px 5px !important;
  top: 50% !important;
  left: 35vw !important;
  width: 100%;
  max-width: unset;
  max-height: 100%;
  position: fixed;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  overflow-y: hidden;
  overflow-x: hidden;
}
.miniAppTAndCBox.confirmationBox.covidAlertBox {
  padding-top: 10px !important;
}

.covidInfo {
  border-bottom: 1px solid #fff;
  padding: 5px;
  //position: absolute;
  width: 100%;
  top: 0;
  background-color: #00aeff;
  z-index: 2;
}

.covidInfoTitle {
  font-size: 15px !important;

  .highlight {
    //color: #fff000 !important;
    font-weight: bold;
  }
}

.reliefTitle {
  font-size: 14px;
  font-weight: 600;
  font-family: Arial, Myanmar3 !important;
  line-height: 30px;
  color: #fff;
  text-align: center;
  margin-left: 5px;
}

.reliefTitle-highlight a {
  color: #fff000;

  &:hover {
    color: darken(#fff000, 1%);
  }

  &:visited,
  &:active {
    color: #fff000;
  }
}

.btnClose {
  float: right;
  color: #000 !important;
  font-size: 30px;
  top: 10px;
  right: 10px;
  position: absolute;
  cursor: pointer;
}

.covidAlertBox {
  padding: 5px 0px !important;

  img.closeImg {
    cursor: pointer;
  }

  img {
    width: 22px;
    position: relative;
    top: 5px;
    right: 5px;
  }

  .confirmationBoxTitle {
    text-align: left !important;
    font-family: "Poppins", Myanmar3 !important;
    line-height: 22px;

    .informationDiv {
      font-size: 14px !important;
      font-weight: 400 !important;
      text-align: left !important;
      font-family: "Poppins", Myanmar3 !important;

      a {
        color: #000 !important;
        text-decoration: underline !important;
        cursor: pointer;
      }
    }
  }
}

.bgWhiteBanner {
  background-color: #29abe2;
}

.menu a {
  display: inline-flex;
  align-items: center;
}

@media (min-width: 600px) and (max-width: 767px) {
  .progressbar li:not(:last-child):after {
    width: 60%;
  }
}
@media (min-width: 600px) {
  .MuiMenuItem-root {
    min-height: 17px !important;
  }
}
@media (max-width: 767px) {
  .progressBarContainer {
    // justify-content: center;
  }

  .progressbar li {
    span {
      display: none;
    }

    &:last-child {
      width: unset !important;
      min-width: unset !important;
    }
  }

  .language-select {
    padding-right: 8px;
    justify-content: flex-end;
  }

  .selectedDepartureBox .detailBox {
    padding: 10px !important;
    margin-top: 20px;
  }

  div.airlineInfo.departureFlight {
    height: 80px;
    margin: auto !important;

    span img {
      width: 40px !important;
    }
  }
}

@media (min-width: 768px) and (max-width: 959px) {
  .mm-progress.progressbar,
  .eng-progress.progressbar {
    // margin-left: 20%;
  }

  .eng-progress.progressbar li {
    min-width: 110px !important;
  }

  .eng-progress.progressbar li:not(:last-child):after {
    width: 50%;
    right: 5px;
  }

  .progressbar li:not(:last-child):after {
    right: 0px;
    width: 25%;
    bottom: 19px !important;
  }

  .mm-progress.progressbar li:first-child {
    min-width: 100px !important;
  }
}

//tablet size and above
@media (min-width: 768px) {
  .progressBarContainer {
    // justify-content: center;
  }

  .language-select {
    justify-content: flex-end;
  }

  .progressbar .completed:before {
    width: 15px !important;
  }

  .mm-progressbar.progressbar {
    padding-top: 0;
  }

  .eng-progress.progressbar {
    padding-inline-start: 0px !important;
  }

  .mm-progress.progressbar li {
    align-items: center;
    min-width: 120px !important;
    width: unset !important;
    padding-left: 13px;
    padding-right: 13px;
  }
}

@media (min-width: 960px) {
  // .choosePassenger {
  //   max-width: unset;
  // }
  .progressbar {
    padding-top: 0;
  }

  .progressbar li:before {
    width: 14px;
    height: 14px;
  }

  .header .progressbar li {
    min-width: 110px !important;
  }

  .eng-progress.progressbar {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }

  .eng-progress.progressbar li:not(:last-child):after {
    width: 40%;
    bottom: 12px !important;
  }

  .mm-progress.progressbar {
    padding-top: 0;

    li {
      width: unset;
      min-width: 130px !important;
      display: block;
      padding-left: 10px;
      padding-right: 10px;

      &:not(:last-child):after {
        right: 0;
        z-index: 5;
        bottom: 11px !important;
      }

      &:first-child {
        min-width: 110px !important;
      }

      &:nth-child(3) {
        min-width: 115px !important;
      }
    }
  }

  .headerLogoContainer {
    display: flex;
    align-items: center;
    height: 60px;
  }

  .language-select {
    height: 60px;
    display: flex;
    align-items: center;
  }

  .infoSection .departTime {
    // margin-left: 47px;
  }

  .infoSection .airlnelbl {
    // margin-left: 20px;
  }

  .arlneLogo {
    // margin-top: -24px;
  }

  .arlneLogo img {
    width: 44px;
  }
}

.headerLogoContainer {
  display: flex;
  align-items: center;
  height: 60px;
}

@media (min-width: 600px) and (max-width: 767px) {
  .progressbar li:not(:last-child):after {
    width: 60%;
  }
}

@media (max-width: 767px) {
  .progressBarContainer {
    justify-content: flex-start;
    padding-left: 16px;
    flex-basis: initial !important;
    margin-right: auto !important;
  }

  .progressbar li {
    span {
      display: none;
    }

    &:last-child {
      width: unset !important;
      min-width: unset !important;
    }
  }

  .language-select {
    padding-right: 16px;
    justify-content: flex-end;
  }

  .selectedDepartureBox .detailBox {
    padding: 10px !important;
    margin-top: 20px;
  }

  div.airlineInfo.departureFlight {
    height: 80px;
    margin: auto !important;

    span img {
      width: 40px !important;
    }
  }
}

@media (min-width: 768px) and (max-width: 959px) {
  .mm-progress.progressbar,
  .eng-progress.progressbar {
    // margin-left: 20%;
  }

  .eng-progress.progressbar li {
    min-width: 110px !important;
  }

  .eng-progress.progressbar li:not(:last-child):after {
    width: 50%;
    right: 5px;
  }

  .progressbar li:not(:last-child):after {
    right: 0px;
    width: 25%;
    bottom: 19px !important;
  }

  .mm-progress.progressbar li:first-child {
    min-width: 100px !important;
  }
}

//tablet size and above
@media (min-width: 768px) {
  .progressBarContainer {
    // justify-content: center;
  }

  .language-select {
    justify-content: flex-end;
  }

  .progressbar .completed:before {
    width: 5px !important;
  }

  .mm-progressbar.progressbar {
    padding-top: 0;
  }

  .eng-progress.progressbar {
    padding-inline-start: 0px !important;
  }

  .mm-progress.progressbar li {
    align-items: center;
    min-width: 120px !important;
    width: unset !important;
    padding-left: 13px;
    padding-right: 13px;
  }
}

@media (min-width: 960px) {
  // .choosePassenger {
  //   max-width: unset;
  // }
  .progressbar {
    padding-top: 0;
  }

  .progressbar li:before {
    width: 14px;
    height: 14px;
    margin: 3px 2px 0px 0px !important;
  }

  .header .progressbar li {
    min-width: 110px !important;
  }

  .eng-progress.progressbar {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }

  .eng-progress.progressbar li:not(:last-child):after {
    width: 40%;
    bottom: 12px !important;
  }

  .mm-progress.progressbar {
    padding-top: 0;

    li {
      width: unset;
      min-width: 130px !important;
      display: block;
      padding-left: 10px;
      padding-right: 10px;

      &:not(:last-child):after {
        right: 0;
        z-index: 5;
        bottom: 11px !important;
      }

      &:first-child {
        min-width: 110px !important;
      }

      &:nth-child(3) {
        min-width: 115px !important;
      }
    }
  }

  .headerLogoContainer {
    display: flex;
    align-items: center;
    height: 60px;
  }

  .language-select {
    height: 60px;
    display: flex;
    align-items: center;
  }

  .infoSection .departTime {
    // margin-left: 47px;
  }

  .infoSection .airlnelbl {
    // margin-left: 20px;
  }

  .arlneLogo {
    // margin-top: -24px;
  }

  .arlneLogo img {
    width: 44px;
  }
}

.progressbar {
  counter-reset: step;
  font-size: 12px;
  text-align: right;
  max-width: 100%;
  text-align: center;

  li {
    position: relative !important;
  }
}

// .progressbar.mm-progress {
//   max-width: 95%;
//   // padding-inline-start: 0px !important;
// }
// .eng-progress {
//   float: right;
//   li:nth-child(2) {
//     width: 23% !important;
//   }
// }
.progressbar.eng-progress,
.progressbar.mm-progress {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  align-self: center;
}

.progressbar li {
  list-style-type: none;
  width: 23%;
  font-size: 12px;
  position: relative;
  text-align: center;
  text-transform: capitalize;
  color: #a1a1a1;
  height: 30px;
  line-height: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 110px !important;

  span {
    float: left;
    margin-top: 3px;
    margin-left: 3px;
  }
}

// .mm-progress li:nth-child(2) {
//   width: 27% !important;
// }
// .mm-progress li:last-child {
//   width: 18% !important;
// }
.progressbar li:before {
  width: 16px;
  height: 16px;
  content: counter(step);
  counter-increment: step;
  color: #fff;
  line-height: 15px;
  border: 2px solid rgb(161, 161, 161);
  display: block;
  text-align: center;
  margin: 8px auto 0px auto;
  border-radius: 50%;
  background-color: rgb(161, 161, 161);
  float: left;
}

.progressbar .completed:before {
  content: url("../images/checked-symbol.svg") !important;
  background-color: #fff !important;
  border: none !important;
  margin-top: 8px;
  width: 20px !important;
  transform: scale(0.8);
}

.progressbar li:not(:last-child):after {
  background-color: #a1a1a1;
  width: 12px;
  height: 2px;
  content: "";
  position: absolute;
  right: 5px;
  z-index: 5;
  bottom: 14px !important;
}

.progressbar li:last-child:after {
  content: none !important;
}
.bullet {
  margin: 0px 5px;
  font-size: 18px;
  top: 2px;
  position: relative;
}

.step-info {
  min-width: 30px;
  color: #a1a1a1;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 400;
  font-size: 14px;
}

.header {
  height: 60px;
  border-bottom: 1px solid #e6e6e6;
  background-color: #fff !important;
}

.headerLogo {
  display: block !important;
}

a {
  text-decoration: none;
  color: #fff;
}

.badge-icon {
  padding-left: 5px;
}

/***** Start Home Login Modal Box Css *****/
#logout {
  .MuiPaper-root.MuiPopover-paper {
    padding: 10px 0;
    width: 200px;

    .emailAddressLoginPopoverText {
      margin-top: 0px;
      margin-bottom: 0px;
    }

    span {
      width: 100%;
      display: inline-block;
      margin: 5px 0 10px 0;
      padding: 0 10px;
      text-align: left;
    }

    span:hover {
      cursor: pointer;
      color: rgb(13, 135, 221);
    }

    span.signedTitle:hover {
      color: #000;
      cursor: default;
    }
  }
}

.login-inner .login-divider {
  display: flex;
  margin-top: 15px;
  clear: both;
  .border-line {
    width: 35%;
  }
  .border-title {
    width: 30%;
    text-align: center;
    color: #878787;
    font-size: 14px;
    font-family: "Roboto";
  }
}

.signup-divider {
  display: flex;
  margin-top: 20px;
  .border-line {
    width: 25%;
  }
  .border-title {
    width: 50%;
    text-align: center;
    color: #878787;
    font-size: 14px;
    font-family: "Roboto";
  }
}

.signup-btn {
  margin-top: 0 !important;
  font-family: "Roboto" !important;
  font-size: 15px !important;
  border-radius: 5px !important;
  padding: 6px 8px !important;
}

.login-div.signin-box-container {
  width: 460px;
}

.login-div.signup-box-container {
  width: 550px;
}

.login-div {
  background: #fff;
  margin: 0 auto;
  margin-top: 40px;
  outline: none;
  border-radius: 7px;
  box-shadow: 0px 0px 48.5px 4px #47474780;

  .login-left {
    .login-left-inner.signin {
      padding: 20px 40px;

      .react-tel-input .flag-dropdown {
        background-color: #fff;
      }
    }

    .login-left-inner.email-sent {
      text-align: center;
      margin-top: 30px;
      max-width: 300px;

      img {
        width: 82px;
        height: auto;
        position: initial;
      }

      p {
        font-family: "Poppins", Myanmar3;
        font-size: 14px;
        color: #717171;
      }

      span.info {
        font-family: "Poppins", Myanmar3;
        font-size: 13px;
        color: #717171;
      }
    }

    button {
      font-family: "Poppins", Myanmar3;
      display: inline-block;
      width: 100%;
      text-align: center;
      color: #fff;
      border-radius: 4px;
      margin-top: 10px;

      .MuiCircularProgress-root {
        width: 20px !important;
        height: 20px !important;
        color: #fff;
      }
    }
    .sign-text .MuiCircularProgress-root {
      color: #0b55a8 !important;
    }
    .btn-login-box {
      background-color: #fff;
      border: 1px solid #0b55a8;
      border-radius: 5px;
      display: flex;
      color: #7c7c7c;

      .MuiButton-label {
        font-size: 14px;
        font-family: "Roboto";
      }
    }

    .create-account-btn {
      background-color: #fff;
      border: 1px solid #219eed;
      border-radius: 5px;
      display: flex;
      color: #219eed;
      font-size: 14px;
      font-family: "Roboto";
    }

    .MuiButton-label {
      text-transform: none;
    }

    .google-login button {
      width: 90%;
      margin-left: 20px;
    }

    .phone-login button {
      width: 90%;
      margin-left: 20px;
    }

    .signuptab-social-login {
      display: flex;
      margin-top: 40px;
    }

    .login-left-inner.signup {
      padding: 5px 25px 20px 25px;

      .create-account-form {
        label {
          color: #5d5d5d;
          display: flex;
          font-size: 13px;
          margin-bottom: 2px;
        }
        input {
          border: 1px solid #eaeaea;
          border-radius: 5px;
          font-size: 13px;
          margin-bottom: 10px;
          outline: none;
          padding: 10px 20px;
        }
        .first_name {
          input {
            width: 70%;
          }
        }
        .last_name {
          input {
            width: 78%;
          }
        }
        .email_input {
          input {
            width: 91%;
          }
        }
        .password {
          input {
            width: 91%;
          }
        }
        .signup-box-phone {
          border: 1px solid #eaeaea;
          border-radius: 0;
          height: 35px;
          margin-bottom: 0;
          padding: 0 10px !important;
          width: 94%;
        }
      }

      .phone-container {
        position: relative;

        .react-tel-input {
          width: 100% !important;
          border-top: 1px solid #eaeaea;
          border-bottom: 1px solid #eaeaea;
          border-left: 1px solid #eaeaea;
          border-right: 0 !important;

          .flag-dropdown {
            border: 0px;
          }
          .MuiInputBase-formControl {
            border-radius: 0;
            border-bottom: 0;
            font-size: 13px !important;
            width: 98%;
            border: none;
            padding-left: 48px !important;
          }
        }
      }

      .signup-more-div {
        .MuiPaper-root {
          box-shadow: none;
          border: 1px solid #eaeaea;
          border-radius: 5px;
        }
        .MuiAccordionSummary-content p {
          margin: 0;
          font-size: 14px;
          font-family: "Roboto", Myanmar3;
        }
        .dobSelects,
        .nrcSelects,
        .passSelects {
          display: flex;
        }
        .selectBox {
          border-radius: 5px;
          font-size: 13px;
          margin-bottom: 10px;
          outline: none;
          padding: 0 2px !important;
          width: 98% !important;
          border: none !important;
        }
      }

      .nationality {
        border-radius: 5px;
        font-size: 13px;
        margin-bottom: 10px;
        outline: none;
        width: 100%;
      }

      .nationality-grid {
        padding-left: 10px;
      }

      .selectBox {
        border-radius: 5px;
        font-size: 13px;
        margin-bottom: 10px;
        outline: none;
        padding: 8px;
      }

      .selectBoxFullWidthSignUpMobile {
        @media (max-width: 780px) {
          width: 100%;
        }
      }

      .regionSelect {
        width: 90%;
      }

      .nrcRegion,
      .dobRegion {
        margin-bottom: 0;
      }

      .nrcTextField {
        width: 90%;
      }

      .nrcNumber {
        width: 88%;
        margin-left: 5px;
        padding: 9px !important;
      }

      .passNumber {
        width: 88%;
        padding: 9px !important;
      }

      textarea {
        border: 1px solid #eaeaea;
        border-radius: 5px;
        font-size: 13px;
        outline: none;
        padding: 8px;
        width: 96%;
      }
    }

    .login-left-inner {
      height: auto;
      margin: 0 auto;

      .dim-div {
        pointer-events: none;
        opacity: 0.7;
      }

      .addressBookDob select,
      .addressBookNrc select,
      .addressBookNrc .nrcTextField {
        width: 98%;
      }
      .addressBookNrc .nrcNumber {
        margin-left: 0;
      }

      .login-title-div,
      .signup-title-div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;
        h3 {
          font-family: "Roboto", Myanmar3;
          font-weight: bold;
          font-size: 1.17em;
          margin-top: 20px;
          color: #4f4f4f;
        }
        img {
          cursor: pointer;
          width: 15px;
          height: 15px;
        }
        svg {
          cursor: pointer;
          width: 20px;
          height: 20px;
          margin-top: 5px;
        }
      }

      .signup-second-title {
        color: #686868;
        font-size: 15px;
        margin-bottom: 20px;
        display: flex;
      }

      h4 {
        color: red;
      }

      .MuiFormLabel-root,
      .MuiInputBase-root {
        font-size: 14px !important;
        width: 100%;
      }

      .error-textbox {
        border-bottom: 1px solid #ed4939 !important;
      }

      .MuiTextField-root {
        display: inline-block;
        width: 100%;
        margin-bottom: 25px;

        .MuiInput-formControl {
          width: 100%;
        }
      }

      .signin-back-btn {
        color: #000;
        border-radius: 4px;
        cursor: pointer;
        margin-top: 30px;
        padding: 8px 15px;
        display: inline-block;
        font-size: 14px;
      }

      .dim-btn-send-otp {
        pointer-events: none;
        opacity: 0.7;
      }

      .btn-cancel {
        width: 45%;
        background-color: #fff;
        border: 1px solid #ed4939;
        color: #ed4939;
      }

      .login-subtitle {
        font-size: 17px;
        color: #000;
        display: inline-block;
        margin-bottom: 30px;
        text-align: center;
        width: 100%;
      }

      p {
        font-size: 11px;
        margin-top: 5px;
        color: #ed4939;
      }

      .signup-error {
        color: #ed4939;
        font-size: 11px;
        margin-bottom: 0px;
      }

      .forgot-email {
        font-size: 14px;
        color: #3cbdfe;
        text-align: center;
        cursor: pointer;
        font-family: "Poppins", Myanmar3;
      }

      .forget-info {
        font-size: 14px;
        color: #717171;
        font-family: "Poppins", Myanmar3;
      }

      .forgot-div {
        text-align: center;
        margin-top: 5px;

        span {
          font-size: 14px;
          color: #948d8d;
          cursor: pointer;
        }
      }
      .phone-container {
        margin-bottom: 10px;
      }
      .react-tel-input {
        border-top: 1px solid #eaeaea;
        border-bottom: 1px solid #eaeaea;
        border-left: 1px solid #eaeaea;
        border-right: 0 !important;

        .flag-dropdown {
          border: 0px;
        }
        .country-name {
          color: #000;
        }
        .MuiInputBase-formControl {
          border-radius: 0;
          border-bottom: 0;
          font-size: 13px !important;
          width: 105px;
          border: none;
        }
      }
      .infobox {
        .login-box-phone {
          margin-bottom: 10px;
          input {
            height: 35px;
            width: 98%;
            padding: 0 10px !important;
            margin-bottom: 0;
            border: 1px solid #eaeaea;
            border-radius: 0;
          }
        }
      }
    }
  }

  #signup-container {
    max-height: 93vh;
    .react-tel-input {
      height: auto !important;
      max-width: auto !important;
      .flag-dropdown {
        width: auto !important;
        border: none !important;
      }
      .country-name {
        color: #000;
      }
      .selected-flag .arrow {
        left: 20px !important;
      }
    }
  }
}

.login-container {
  #btn-sign-in,
  .btn-send-otp,
  .forgot-email {
    font-family: "Roboto" !important;
    font-size: 16px !important;
    font-weight: 400;
  }
}

.profile-edit-div {
  width: 100%;
  .MuiPaper-root {
    box-shadow: none;
    border: 1px solid #eaeaea;
    border-radius: 5px;
  }
  .MuiAccordionSummary-content p {
    margin: 0;
    font-size: 14px;
    font-family: "Roboto", Myanmar3;
  }
  .dobSelects,
  .nrcSelects,
  .passSelects {
    display: flex;
  }
  .selectBox {
    border: 1px solid #eaeaea;
    border-radius: 5px;
    font-size: 13px;
    margin-bottom: 10px;
    outline: none;
    padding: 8px 2px;
    width: 98%;
  }
  .nrcTextField,
  .regionSelect {
    width: 98% !important;
  }
  .nrcNumber {
    margin-left: 0 !important;
  }
}

.login-inner {
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}

@media (max-width: 780px) {
  .headerLogoContainer {
    padding-left: 15px;
  }
  .selectBox.nrcNumberField {
    padding: 8px 0px !important;
  }
  .login-div {
    display: block !important;

    .login-inner {
      height: 87vh;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    .login-left {
      height: auto;

      .login-left-inner.signup {
        .create-account-form {
          @media (max-width: 780px) {
            margin-top: 10px;
          }
          .first_name input,
          .last_name input,
          .email_input input,
          .password input {
            width: 86% !important;
          }
        }

        .signup-box-phone {
          width: 89% !important;
        }

        input {
          width: 100% !important;
        }

        input.nrcNumber {
          width: 80% !important;
        }

        input.passNumber {
          width: 93% !important;
        }

        .textarea {
          width: 92% !important;
        }

        .phone-container {
          .countryCode {
            left: 35px;
            margin-left: 0 !important;
          }
        }

        .nationality-grid {
          padding-left: 0;
        }
      }
    }
  }
}

/***** End Home Login Modal Box Css *****/

/*payment image sprite*/
.payment-img-visa {
  background-position: 0px 0px;
  background-size: 237px;
  width: 70px;
  height: 44px;
  float: left;
}

.payment-img-mobile-banking {
  background-position: -84px 0px;
  background-size: 237px;
  width: 70px;
  height: 44px;
  float: left;
}

.payment-img-mpu {
  background-position: -168px 0px;
  width: 70px;
  background-size: 237px;
  height: 44px;
  float: left;
}

.payment-img-123 {
  background-position: 0px -53px;
  background-size: 237px;
  width: 70px;
  height: 44px;
  float: left;
}

.payment-img-kbz-quickpay {
  background-position: -86px -52px;
  background-size: 237px;
  width: 70px;
  height: 44px;
  float: left;
}

.payment-img-cod {
  background-position: -168px -52px;
  background-size: 237px;
  width: 70px;
  height: 44px;
  float: left;
}

.payment-img-wave-pay {
  background-position: 0px -107px;
  width: 70px;
  height: 44px;
  float: left;
}

.payment-img-kbz-pay {
  background-position: -86px -105px;
  background-size: 237px;
  width: 70px;
  height: 44px;
  float: left;
}

.payment-img-citizen-pay {
  background-position: -86px -165px;
  background-size: 237px;
  width: 70px;
  height: 44px;
  float: left;
}

.payment-img-one-pay {
  background-position: -168px -105px;
  background-size: 237px;
  width: 70px;
  height: 44px;
  float: left;
}

.payment-img-mpitesan {
  background-position: 0px -166px;
  width: 70px;
  height: 44px;
  float: left;
}

/*payment image sprite*/
.confirmationBox.busInfo {
  position: absolute !important;
  left: 50% !important;
  top: 50% !important;
  -webkit-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
}

.agentSwitch {
  float: right;
  font-size: 14px;
}

.signIn_btn {
  font-weight: 600;
  cursor: pointer;
}

.member-account {
  text-align: center;
  font-size: 14px;
  padding: 0 40px 0 40px;
  .ask-signin-div {
    border: 1px solid #219eed;
    border-radius: 5px;
    padding: 6px 8px;
    line-height: 1.75;
    clear: both;
    span {
      color: #219eed;
      font-size: 15px;
      font-weight: 500;
      font-family: "Roboto";
    }
  }
}

.agentCheck {
  line-height: 40px;
  text-align: center;
}

.textLeft {
  text-align: left !important;
}

.confirmationBox.promotionBox {
  padding: 0px 7px 7px 7px !important;
  top: 50% !important;
  left: 50% !important;
  width: 420px;
  max-width: unset;
  max-height: 100%;
  position: fixed;
  top: 55% !important;
  left: 0;
  transform: translate(-50%, -50%);
  // overflow-y: hidden;
  // overflow-x: hidden;
  background-color: transparent;
  // background-color: #fff !important;
  border-radius: 5px;
  text-align: left !important;

  p {
    margin: 0px !important;
  }

  .promoDesc {
    background-color: #fff;
    // overflow: scroll;
    // max-height: 285px;
  }
  .carousel-PREV-arrow,
  .carousel-NEXT-arrow {
    visibility: visible;
  }
}

.google-btn {
  color: #000000 !important;
  display: flex !important;
  background-color: #fff !important;
}

.btn-sign-in.btn-facebook {
  display: flex !important;
}

.otpDiv {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
}

.phoneInfoNew {
  width: 300px;
  margin: -10px 0px 8px 5px;
}

.phoneInfo {
  font-size: 12px;
  color: #a1a1a1 !important;
}

.confirmationBox.promotionBox:focus {
  border: none !important;
  outline: none !important;
}

.promoImageGallery {
  margin-top: 30px;

  .image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: 200px;
    object-fit: cover;
  }
}

.mobContactUsMenu a {
  padding: 10px !important;
  float: left !important;
}

.pagi-region {
  display: flex;
  margin-top: 20px;
  margin-bottom: 80px;
  align-self: flex-start;
  margin-left: 20px;

  select {
    display: inline-flex;
    margin-left: 10px;
    height: 32px;
    padding: 0px 10px;
    outline: none;
    border: 1px solid #e3e3e3;
  }

  span {
    display: inline-flex;
    line-height: 30px;
    margin-left: 10px;
    font-size: 14px;
  }
}

ul.common-pagination {
  display: inline-flex;
  padding-left: 1px;
  list-style: none;
  border-radius: 0.25rem;
  margin: 0;

  li.active {
    a {
      color: #fff;
      background-color: #20a8d8;
      border-color: #20a8d8;
    }
  }

  li.disabled {
    a {
      color: #73818f;
      pointer-events: none;
      cursor: auto;
      background-color: #fff;
      border-color: #e3e3e3;
    }
  }

  li {
    a {
      position: relative;
      display: block;
      padding: 5px 10px;
      margin-left: -1px;
      line-height: 1.25;
      color: #20a8d8;
      background-color: #fff;
      border: 1px solid #e3e3e3;
      cursor: pointer;
    }
  }
}

.promoDesc a {
  color: #20a8d8 !important;
}

.google-btn {
  color: #000000 !important;
  display: flex !important;
  background-color: #fff !important;
}

.btn-sign-in.btn-facebook {
  display: flex !important;
}

.phoneInfo {
  font-size: 12px;
  color: #a1a1a1 !important;
}

.div-login-tab {
  display: flex;
}

.login-tab {
  width: 50%;
  padding: 10px;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  cursor: pointer;
  color: #cecece;
}

.login-tab.active {
  color: #219eed !important;
  border-bottom: 1px solid #219eed;
}

.infobox {
  padding: 20px 0 0 0;
  display: flex;
  flex-direction: column;
  label {
    color: #5d5d5d;
    font-size: 13px;
    margin-bottom: 7px;
    display: flex;
  }
  input {
    border: 1px solid #eaeaea;
    border-radius: 5px;
    width: 88%;
    margin-bottom: 15px;
    padding: 10px 20px;
    font-size: 13px;
    outline: none;
  }
  input::placeholder {
    color: #b5b0b0;
  }
}

.infobox-loginbuttons {
  margin-top: 15px;
  padding-bottom: 50px !important;
}
.signUpOptBox {
  padding: 0 40px 20px 40px;
  button {
    margin: auto;
    line-height: 28px;
    font-family: "Roboto" !important;
    font-size: 15px;
  }
}

#mui-component-select-sortBy {
  padding: 0px 0px 0px 10px !important;
  min-width: 120px !important;
  background-color: #fff !important;
  min-height: 30px;
  border-radius: 4px;
  line-height: 30px;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 400;
  font-size: 14px !important;
  border: 1px solid #e4e4e4;
}

.sortingBox .MuiSvgIcon-root {
  color: rgba(0, 0, 0, 0.54) !important;
}

.sortingBox .promoSortBox {
  width: 200px;
  float: right;
  text-align: right;
  margin: 20px;
  #mui-component-select-sortBy {
    background-color: transparent !important;
    border: none !important;
  }
  @media (max-width: 530px) {
    width: 38%;
    margin: 10px 20px;
    // display: none;
  }
  .sortby-txt {
    @media (max-width: 530px) {
      display: none;
    }
  }
}

.sortingBox .promoSortBox span {
  font-size: 14px;
  line-height: 35px;
}

.b2bTransferBox {
  background-color: white;
  padding: 20px;
  height: 330px;
}

.btn-transfer {
  width: 120px;
  float: right;
  margin: 20px 0px 0px 0px !important;
  background-color: #00aeef !important;
  color: #fff !important;
  text-transform: capitalize !important;
}

.transferAmountBox {
  border: 1px solid #eee !important;
  height: 330px !important;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
}

.airportName {
  display: flex;
  flex-direction: row;
  justify-content: "space-between";
}

.airports {
  display: flex;
  flex-direction: row;
}

//.airportNames:hover,
.busCityName:hover {
  cursor: pointer;
  color: #00aeef;
}

.airportDisableNames:hover {
  cursor: not-allowed;
  //color: #00aeef;
}

.intAirportNameDisable {
  opacity: 0.4 !important;
  pointer-events: none;

  &:hover {
    color: black !important;
    cursor: not-allowed;
  }
}

#international .MuiFormControlLabel-label,
#domestic .MuiFormControlLabel-label {
  margin-top: 8px;
}

#international .MuiIconButton-label,
#domestic .MuiIconButton-label {
  margin-top: 2px;
}

.promo-page-header-container,
.tourpackage-page-header-container,
.insurpolicy-page-header-container,
.verify-page-header-container {
  max-width: 1300px;
  width: unset !important;
  margin: auto;
  @media (max-width: 781px) {
    max-width: auto;
    width: 93%;
  }
}

.employeePage .employee-page-header {
  padding-top: 5px;
  max-width: 1100px !important;
  button {
    span {
      line-height: 15px;
    }
    &.active {
      span {
        font-weight: 600;
      }
    }
  }
}

.employeeModal {
  p {
    margin: 0;
  }
}

// .promo-page-covid-banner-alert-space {
//   @media (min-width: 781px) {
//     margin-top: 35px;
//   }
// }

.flightToPopup.bottom.airport-popup:after,
.flightToPopup.bottom.airport-popup-flight:after {
  content: "";
  width: 26px;
  height: 14px;
  transform: rotate(-45deg);
  background: #01b7f2;
  position: absolute;
  border: 1px solid #e6e7e8;
  z-index: -1;
  // bottom: 3px;
  top: 0px;
  left: 15%;
  position: absolute;
}

.right.airport-popup:after,
.left.airport-popup:after,
.right.airport-popup-flight:after,
.left.airport-popup-flight:after {
  content: "";
  transform: rotate(-45deg);
  background: #01b7f2;
  position: absolute;
  border: 1px solid #e6e7e8;
  z-index: -1;
  left: 15%;
  margin-left: -12px;
  width: 26px;
  height: 14px;
  overflow: hidden;
  margin-top: -3px;
  top: 0px;
}
.toAirport.airport-popup:after,
.toAirport.airport-popup-flight:after {
  left: 40% !important;
}

.DateInput_fangStroke {
  stroke: #01b7f2 !important;
  fill: #01b7f2 !important;
}

.promotion-seemore {
  border-radius: 5px;
  color: #00aeef;
  font-size: 14px;
  cursor: pointer;
  display: block;
  margin: 0 auto;
  width: 15%;
  text-align: center;
  font-family: "Roboto";
  font-weight: 500;
  padding: 7px 0;
  margin-bottom: 20px;
  @media (max-width: 500px) {
    width: 40%;
  }
}
.inWeb.bgCityReward.promotion-seemore {
  border: 1px solid #6e51a2 !important;
  color: #6e51a2 !important;
}
.inWeb.bgKbz.promotion-seemore {
  border: 1px solid #0b55a8 !important;
  color: #0b55a8 !important;
}
.inWeb.bgViber.promotion-seemore {
  border: 1px solid #794aff !important;
  color: #794aff !important;
}

.newMenuList {
  margin-top: 50px !important;
}

.flymyaSlogan {
  // font-family: "Poppins", Myanmar3 !important;
  margin: 0;
  font-size: 22px !important;
  color: #2e2e2e;
  text-align: center;
  font-weight: 500;
  // padding-top: 12px;
  //padding-left: 40px;
}
.inWeb .flymyaSlogan {
  padding-left: 0px !important;
}
.verifyTicket {
  position: relative;
  // width: 113px;
  display: flex;
  // padding-right: 10px;
  cursor: pointer;
  font-size: 16px !important;
  // margin: 0 !important;
  padding-top: 0 !important;
}

.header-verifyticket {
  cursor: pointer;
  span {
    // font-size: 14px;
    margin-left: 0 !important;
  }
}
.header-contactus {
  cursor: pointer;
  span {
    // font-size: 14px;
    margin-left: 0 !important;
  }
}
.menu a .blogMenu {
  font-size: 16px !important;
  margin: 0;
  @media (max-width: 780px) {
    font-size: 15px !important;
  }
}
.descBox {
  width: 70%;
  padding: 8px !important;
  border: 1px solid gray !important;
  border-radius: 5px;
  fieldset {
    border: none !important;
  }
}

#gorgias-chat-container iframe#chat-button {
  display: none;
}
.checkAmountBox .MuiInputBase-input {
  padding: 5px 10px !important;
}
.checkDescriptionBox .MuiInputBase-input {
  padding: 5px 30px !important;
}
.form-control.bugReport {
  border-radius: 5px;
  border: 1px solid gray;
  padding: 10px;
  width: 70%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.file-name.bugReport {
  color: #20a8d8 !important;
  font-size: 14px !important;
}
.fileupload.bugReport {
  margin: auto;
  width: 80%;
  margin-top: 10px;
  overflow: hidden;
}
.errMsg.bugReport {
  margin: auto;
  color: red;
  margin-top: 10px;
}
.btnError.bugReport {
  opacity: 0.4;
}
.feedbackBtn {
  padding: 3px 20px !important;
  font-size: large !important;
}

.route-tag {
  margin: 0;
  width: max-content;
  min-width: 60px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  border-radius: 5px;
  margin-top: 10px;
  float: left;
  &.online {
    background-color: #c8eaf9;
    margin-bottom: 3px;
  }
  &.offline {
    background-color: #ffe1ae;
  }
  @media (max-width: 600px) {
    margin-top: 5px;
  }
  &.hotel-tag {
    margin-left: 15px;
    @media (max-width: 780px) {
      margin-top: 15px;
      position: absolute;
      left: 10px;
      z-index: 1;
    }
  }
}

.kbzModal {
  z-index: 9999 !important;
  .MuiBox-root {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400;
    background-color: #fff;
    // padding: 20px;
    outline: none;
    border-radius: 8px;
    overflow-y: scroll;
    max-height: 550px;
  }
  .typo-wrap {
    padding: 20px 20px 5px 20px;
  }

  button {
    border-radius: 0;
  }
  .yesBtn {
    padding-block: 10px;
    width: 50%;
    border: 1px solid rgba(11, 85, 168, 0.5);
  }
  .noBtn {
    padding-block: 10px;
    width: 50%;
    border-left: 0 !important;
  }
  .button_div {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .btnNew {
    padding-block: 6px;
    width: 35%;
    border: 1px solid rgba(11, 85, 168, 0.5);
    border-radius: 4px;
  }

  .noBtn:hover {
    background-color: #0b55a8;
    color: #fff;
    opacity: 0.8;
  }
}
@media (max-width: 780px) {
  .kbzModal {
    z-index: 9999 !important;
    .MuiBox-root {
      width: 80% !important;
    }
  }
}
.viber-btn.Mui-disabled {
  svg {
    opacity: 0.5;
  }
  .maintenance-text {
    font-size: 12px;
    color: red;
    font-weight: 600;
  }
}
.contactUsIconBox {
  .MuiButton-label {
    display: flex;
    flex-direction: column;
  }
  .btn-wrap {
    width: 20%;
    position: relative;
    button.Mui-disabled {
      svg {
        opacity: 0.5;
      }
    }
    .maintenance-text {
      position: absolute;
      bottom: 2px;
      font-size: 12px;
      color: red;
      font-weight: 600;
    }
  }
  .btnChoose {
    padding: 40px 0px;
  }
}
.login-benefit {
  text-align: left;
  display: inline-block;
  font-size: 12px;
  line-height: 20px;
  color: "#5E5E5E";
}

.kbz_login_in {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: rgb(248, 245, 245);
  margin: 8px;
  border-radius: 8px;
}

.flight-schedule-modal {
  position: absolute;
  top: 8% !important;
  bottom: -10% !important;
  margin: 0 auto;
  margin-bottom: 30px;
  width: 70%;
  max-height: 80vh;
  display: block;
  outline: none !important;
  .weekly-schedule-snackbar {
    left: 50% !important;
    bottom: 10%;
    .MuiAlert-filledSuccess {
      background-color: #43b678;
    }
  }
  .modal-container {
    outline: none !important;
    .flightScheduleRouteNameContainer {
      padding: 20px 10px 0 10px;
      display: flex;
      justify-content: space-around;
      .tripTypeTitle {
        text-align: center;
        span {
          padding: 5px 10px;
          border-radius: 5px;
          font-size: 13px;
          background-color: #cceffc;
        }
      }
      .fromRouteName {
        border-right: 1px solid #d9d9d9;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 20px 0;
        img {
          position: absolute;
          right: -15px;
          width: 30px;
          cursor: pointer;
        }
      }
      .toRouteName {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .routeName {
        color: #000;
        font-size: 17px;
        font-family: "Roboto";
        margin: 0px 10px 0px 10px;
        font-weight: 500;
        span {
          color: rgba(246, 137, 0, 1);
          font-size: 14px;
        }
      }
    }
    .flightScheduleRouteListContainer {
      display: flex;
      justify-content: space-around;
    }
    .iconContainer {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .flightScheduleDate {
      justify-content: center;
      display: flex;
      flex-direction: row;
      position: relative;
      button {
        display: flex;
        align-items: center;
        position: absolute;
        right: 65px;
        top: 15px;
        padding: 4px 12px;
        background: #cceffc;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25) !important;
        span {
          color: #000;
          font-size: 13px;
          font-family: "Roboto";
          margin-left: 7px;
        }
      }
    }
    .dateName {
      color: #ffffff;
      font-size: 24px;
      font-family: "Roboto";
      margin: 15px 0px 15px 0px;
      font-weight: bold;
    }
    .flightScheduleTableName {
      display: flex;
      flex-direction: row;
    }
    .operatorName {
      color: #ffffff;
      font-size: 15px;
      font-family: "Roboto";
      margin: 18px 0px 18px 0px;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .calendarDate {
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: row;

      div {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 83px;
        height: 55px;
        justify-content: center;
        margin-left: -10px;
        margin-right: -10px;
      }
      .activeDate {
        background-color: #009fe3;
        cursor: pointer;
      }
      .clickDate {
        cursor: pointer;
      }
      .clickDate:hover {
        cursor: pointer;
        background-color: #009fe3;
      }
      .span1 {
        color: #ffffff;
        font-size: 15px;
        font-family: "Roboto";
      }
      .span2 {
        color: #ffffff;
        font-size: 17px;
        font-family: "Roboto";
        font-weight: bold;
        margin-top: 5px;
      }
    }
    .airlineName {
      color: #717171;
      font-size: 16px;
      font-family: "Roboto";
      margin: 18px 0px 18px 0px;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .airline-img {
      margin: auto;
      justify-content: center;
      display: flex;
      align-items: center;
      img {
        width: 96px;
      }
    }
    .availableDateContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      div {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 83px;
        height: 58px;
        justify-content: center;
        // cursor: pointer;
      }
      span {
        font-weight: 400;
        font-family: "Roboto";
        color: #5d5d5d;
        text-align: center;
      }
    }
    .noflightScheduleData {
      color: "black";
      font-size: 20px;
      font-family: "Roboto";
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 240px;
    }
    .listView {
      padding-bottom: 5px;
      overflow-y: scroll;
      max-height: 52vh;
    }
    .clickableInfo {
      background-color: #f68900;
      justify-content: center;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 5px;

      img {
        width: 19px;
        height: 19px;
        margin: 0px 10px 0px 0px;
      }
      .infoText {
        color: #ffffff;
        font-size: 16px;
        font-family: "Roboto";
        font-weight: 500;
      }
    }
  }
}

.flight-schedule-modal-mobile {
  position: absolute;
  top: 5% !important;
  bottom: -10% !important;
  margin: 0 auto;
  margin-bottom: 30px;
  width: 100%;
  display: block;
  max-height: 80vh;
  outline: none !important;
  .weekly-schedule-snackbar {
    left: 2% !important;
    bottom: 10%;
    .MuiAlert-filledSuccess {
      background-color: #43b678;
    }
  }
  .modal-container {
    padding: 10px;
    outline: none !important;
    background-color: #e8ebe8 !important;
    .secondContainer {
      background-color: #fff;
      margin-top: 10px;
    }
    .flightScheduleRouteNameContainer {
      flex-direction: row !important;
      display: flex;
      background-color: #fff;
    }
    .tripTypeTitle {
      text-align: center;
      margin-top: 10px;
      span {
        padding: 5px 10px;
        border-radius: 5px;
        font-size: 13px;
        background-color: #cceffc;
      }
    }
    .fromRouteName {
      border-right: 1px solid #d9d9d9;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      padding: 20px 0;
      img {
        position: absolute;
        right: -15px;
        width: 30px;
        cursor: pointer;
      }
    }
    .toRouteName {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .routeName {
      color: #000;
      font-size: 17px;
      font-family: "Roboto";
      margin: 0px 10px 0px 10px;
      padding: 0 10px 0 15px;
      font-weight: 500;
      span {
        color: rgba(246, 137, 0, 1);
        font-size: 14px;
      }
    }
    .flightScheduleDate {
      justify-content: space-between;
      display: flex;
      flex-direction: row;
      align-items: center;

      .weekName {
        color: #ffffff;
        font-size: 14px;
        font-family: "Roboto";
        font-weight: 600;
      }
    }
    .dateName {
      color: #ffffff;
      font-size: 16px;
      font-family: "Roboto";
      margin: 15px 0px 15px 0px;
      font-weight: bold;
      button {
        margin-left: 20px;
        padding: 4px 12px;
        background: #cceffc;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25) !important;
        span {
          color: #000;
          font-size: 13px;
          font-family: "Roboto";
          margin-left: 7px;
        }
      }
    }
    .calendarDate {
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: row;
      box-shadow: inset 0 5px 5px #33333324;

      div {
        display: flex;
        align-items: center;
        flex-direction: column;
        height: 75px;
        width: 83px;
        justify-content: center;
      }
      .activeDate {
        background-color: #009fe3;
        cursor: pointer;
      }
      .clickDate {
        cursor: pointer;
      }
      .span1 {
        color: #ffffff;
        font-size: 15px;
        font-family: "Roboto";
      }
      .span2 {
        color: #ffffff;
        font-size: 17px;
        font-family: "Roboto";
        font-weight: bold;
        margin-top: 5px;
      }
    }
    .airline-img {
      margin: 0px 0px 0px 10px;
      display: flex;
      height: 60px;
      align-items: center;
      img {
        width: 150px;
      }
    }
    .airlineName {
      color: #717171;
      font-size: 15px;
      font-family: "Roboto";
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .availableDateContainer {
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: row;
      background-color: #fff;

      div {
        display: flex;
        align-items: center;
        flex-direction: column;
        height: 100px;
        width: 83px;
        justify-content: center;
        border: 1px solid #dcdcdc;
      }
      .label_ {
        color: #5d5d5d;
        font-size: 12px;
        font-family: "Roboto";
        font-weight: 400;
        margin-bottom: 5px;
        margin-right: 12px;
      }
      .value_ {
        color: #000;
        font-size: 16px;
        font-family: "Roboto";
        font-weight: 500;
      }
    }
    .noflightScheduleData {
      color: "black";
      font-size: 20px;
      font-family: "Roboto";
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 440px;
      background-color: #fff;
    }
    .listView {
      overflow-y: scroll;
      max-height: 60vh;
      background-color: #e8ebe8;
    }
    .clickableInfo {
      background-color: #f68900;
      justify-content: center;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 6px 5px 6px 5px;

      img {
        width: 16px;
        height: 16px;
        margin: 0px 5px 0px 0px;
      }
      .infoText {
        color: #ffffff;
        font-size: 12px;
        font-family: "Roboto";
        font-weight: 500;
        text-align: justify;
      }
    }
  }
}

@media (min-width: 781px) and (max-width: 859px) {
  .flightSearch {
    .myanmarCitizen {
      display: flex;
      span {
        padding: 0;
        margin: 0 !important;
      }
      .MuiFormGroup-root.MuiFormGroup-row {
        display: flex;
        align-items: center;
        span {
          height: max-content;
        }
      }
    }
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}

/** Start Hotel City Popup Box **/
.hotelPopupBox {
  width: 350px;
  box-shadow: 0px 0px 10px 0px #00000040;
  z-index: 1;
  .hotelType {
    display: flex;
    .MuiBox-root {
      border: 1px solid #000;
      border-radius: 5px;
      padding: 8px 15px;
      font-size: 14px;
      margin-top: 5px;
      cursor: pointer;
    }
    .domestic {
      margin-right: 15px;
    }
    .active {
      border: 1px solid #00aeef !important;
      color: #00aeef !important;
    }
  }
  .hotelPopupInner {
    padding: 20px;
    .hotelListTitle {
      font-size: 17px;
      color: #00aeef;
      font-weight: 600;
      font-family: "Roboto";
      margin-top: 15px;
      margin-bottom: 15px;
    }
    .hotelCity {
      display: flex;
      align-items: center;
      font-size: 14px;
      padding-bottom: 15px;
      cursor: pointer;
    }
    .hotelCity:hover {
      color: #00aeef;
    }
  }
}

@media (max-width: 780px) {
  .hotelPopupBox {
    width: 100%;
    height: 100%;
    .searchContainer {
      background-color: #00aeef;
      padding: 16px;
      position: relative;
      button.close {
        position: absolute;
        min-width: unset;
        padding: 0;
        left: 13px;
        svg {
          color: white;
        }
      }
      .searchTitle {
        text-align: center;
        color: white;
        padding-block: 3px;
      }
      .searchInput {
        width: 100%;
        background-color: white;
        border-radius: 5px;
        padding-block: 7px;
        margin-top: 10px;
        input {
          width: 90%;
          padding: 3px 10px !important;
          font-size: 14px;
        }
        button {
          padding: 0;
          padding-inline: 5px;
          margin-right: 8px;
          border-color: #00aeef;
          color: #00aeef;
          img {
            width: 12px;
            height: 12px;
            margin-right: 5px;
          }
        }
      }
    }
    .hotelPopupInner {
      width: 100%;
      height: 100%;
      padding: 0 !important;
      .hotelType {
        padding-top: 16px;
        padding-inline: 16px;
        justify-content: center;
      }
      .scroll {
        width: 100%;
        height: 60vh;
        overflow-y: scroll;
      }
    }
  }
}
.homeSearchAirport .MuiInputBase-input {
  margin-top: -20px;
  padding-left: 20px !important;
}
.award-container {
  max-width: 1100px !important;
}

.div-award {
  display: flex; /* Enable flexbox */
  flex-wrap: wrap; /* Allow items to wrap */
  justify-content: space-between; /* Distribute space between items */
  margin: 20px; /* Optional: Add margin around the container */
}

.div-award img {
  flex: 0 1 calc(25% - 20px); /* Each image takes 25% width minus spacing */
  max-width: 22%; /* Ensure images do not exceed their container */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 20px; /* Space below each image */
}

/* Optional: Add some styling to improve the appearance */
.div-award img {
  background-color: #fff; /* Optional: Add a border around images */
  border-radius: 10px;
}
/** End Hotel City Popup Box **/

.footer-bottom.div-partner {
  display: flex;
  flex-direction: column;
  line-height: 24px;
  font-size: 14px;
}

/* add social media icon */
.footer-social-media {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.facebook-link {
  background-image: url("../images/social-icons.webp");
  background-size: 180px;
  background-position: 0px 0px;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
}
.instagram-link {
  background-image: url("../images/social-icons.webp");
  background-size: 180px;
  background-position: -37px 0px;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
}
.linkedin-link {
  background-image: url("../images/social-icons.webp");
  background-size: 180px;
  background-position: -75px 0px;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
}
.viber-link {
  background-image: url("../images/social-icons.webp");
  background-size: 180px;
  background-position: -113px 0px;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
}
.tiktok-link {
  background-image: url("../images/social-icons.webp");
  background-size: 180px;
  background-position: -150px 0px;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
}
/* add social media icon */

/* download link */
.footer-download-app {
  display: flex;
  flex-direction: row;
}
.google-play-link {
  background-image: url("../images/app-downloads.webp");
  background-size: 230px;
  background-position: 0px 0px;
  width: 120px;
  height: 40px;
  background-repeat: no-repeat;
}
.app-store-link {
  background-image: url("../images/app-downloads.webp");
  background-size: 230px;
  background-position: -120px 0px;
  width: 110px;
  height: 40px;
  background-repeat: no-repeat;
}
.app-gallery-link {
  background-image: url("../images/app-downloads.webp");
  background-size: 230px;
  background-position: 0px -40px;
  width: 120px;
  height: 40px;
  background-repeat: no-repeat;
}
.download-app-link {
  background-image: url("../images/app-downloads.webp");
  background-size: 230px;
  background-position: -120px -40px;
  width: 110px;
  height: 40px;
  background-repeat: no-repeat;
}
/* download link */

.footer-travel-easy {
  float: left;
  padding-left: 15px;
  font-weight: bold;
  font-size: 12px;
}
.footer-copy-logo {
  margin: 20px 0px !important;
  border-bottom: none !important;
}
.footer-partner-name {
  padding-left: 10px;
}
.footer-partner-img {
  width: 165px;
  margin-top: 15px;
  padding-left: 10px;
}
.div-footer-social-media {
  margin-top: 30px;
}
